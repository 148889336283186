/* eslint-disable*/
import { Autocomplete, Box, Container, Modal, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, IconButton, TablePagination, Button, FormControlLabel, Switch } from "@mui/material";
import axios from "axios";
import { useEffect, useState, useMemo } from "react";
import { API_URL } from "src/utils/constants";
import { PLACE_TYPE } from "src/utils/constants/placeTypes";
import { AddOrEditPlace } from "./AddOrEditPlace";
import PlacesAutocomplete from "src/components/Places/Autocomplete";
import useLocalStorageKey from "src/utils/hooks/useLocalStorageKey"
import { addValueToLocalstorage } from "src/utils/localstorage/addValueToLocalstorage"
import { getValueToLocalstorage } from "src/utils/localstorage/getValueToLocalstorage"
import { removeValueFromLocalstorage } from "src/utils/localstorage/removeValueFromLocalstorage"

function PlacesListPage() {
    const localStorageKey = useLocalStorageKey()
    const [activePlaces, setActivePlaces] = useState([]);
    const [searchFilter, setSearchFilter] = useState({
        isLive: true,
        isActive: true,
    })
    const [placeId, setPlaceId] = useState(location?.placeId || '');

    const [addPlaceOpen, setAddPlaceOpen] = useState(false);
    const [selectedPlace, setSelectedPlace] = useState(null);


    const [selectedActivePlace, setSelectedActivePlace] = useState(null);
    const [placeType, setPlaceType] = useState('');

    const [displayPlaces, setDisplayPlaces] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('label');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [searchTerm, setSearchTerm] = useState('');
    const [densePadding, setDensePadding] = useState(false);
    const getPlacesFromPath = async (query) => {
        const response = await axios.get(`${API_URL}/place/mb?${query}`);
        setDisplayPlaces(response.data);
    }

    useEffect(() => {
        if (selectedActivePlace) {
            let query = `path=${selectedActivePlace}`
            if (placeType) {
                query += `&type=${placeType}`
            }
            Object.keys(searchFilter).forEach(key => query += `&${key}=${searchFilter[key]}`)
            getPlacesFromPath(query);
        }
    }, [selectedActivePlace, placeType, searchFilter])

    useEffect(() => {
        axios.get(`${API_URL}/place/mb?isActive=true`)
            .then((res) => {
                setDisplayPlaces(Object.keys(res.data).length > 0 ? res.data : []);
                setActivePlaces(Object.keys(res.data).length > 0 ? res.data : []);
            }).then(() => {
                const _selectedActivePlace = getValueToLocalstorage(localStorageKey, 'selectedActivePlace')
                if (_selectedActivePlace) {
                    setSelectedActivePlace(_selectedActivePlace)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredRows = useMemo(() => {
        return displayPlaces.filter((row) => {
            return Object.keys(row).some(
                (key) => row[key]?.toString().toLowerCase().includes(searchTerm)
            );
        });
    }, [searchTerm, displayPlaces]);

    const sortedRows = useMemo(() => {
        return filteredRows.sort((a, b) => {
            let aValue = a[orderBy];
            let bValue = b[orderBy];

            aValue = aValue?.toString().toLowerCase();
            bValue = bValue?.toString().toLowerCase();

            if (aValue < bValue) return order === 'asc' ? -1 : 1;
            if (aValue > bValue) return order === 'asc' ? 1 : -1;
            return 0;
        });
    }, [filteredRows, orderBy, order]);

    const paginatedRows = useMemo(() => {
        return sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }, [sortedRows, page, rowsPerPage]);

    return (
        <Container>
            <div className="grid grid-cols-1 gap-4">
                <div className="flex flex-col  justify-between mb-2">
                    <div className="flex items-center justify-between w-full">
                        <h1 className="font-bold text-xl">Places</h1>
                        <button
                            onClick={() => setAddPlaceOpen(true)}
                            className="bg-[#FF455B] hover:bg-[#da4848] text-white font-extralight p-1.5 px-3 rounded-lg whitespace-nowrap">Add Place
                        </button>
                    </div>
                    <div className="flex flex-col gap-1">
                        <div className="mb-8 mt-2">
                            <PlacesAutocomplete onChange={(newPlace) => {
                                window.open(`/dashboard/edit-place/${newPlace?.id}`, '_blank')
                            }}
                                filters={{
                                    project: 'slug',
                                }}
                            />
                        </div>
                        <h1 className="text-lf font-bold">Filter</h1>
                        <div className="flex items-center gap-5 w-full mt-3">
                            <Autocomplete
                                id="combo-box-demo"
                                options={activePlaces}
                                sx={{ width: 340 }}
                                renderInput={(params) => <TextField {...params} label="Active Places" />}
                                onChange={(_, newValue) => {
                                    if (newValue) {
                                        setSelectedActivePlace(newValue.id);
                                        addValueToLocalstorage(localStorageKey, { selectedActivePlace: newValue.id })

                                    } else {
                                        setSelectedActivePlace(null);
                                        removeValueFromLocalstorage(localStorageKey, 'selectedActivePlace')

                                    }
                                }}
                                value={activePlaces.find(place => place.id === selectedActivePlace)?.label || ''}
                            />
                            <Autocomplete
                                id="combo-box-demo"
                                options={Object.entries(PLACE_TYPE).map(([key, value]) => ({ id: value, label: key }))}
                                sx={{ width: 340 }}
                                renderInput={(params) => <TextField {...params} label="Place Type" />}
                                onChange={(_, newValue) => {
                                    if (newValue) {
                                        setPlaceType(newValue?.id || '');
                                    } else {
                                        setPlaceType('');
                                    }
                                }}
                                value={Object.entries(PLACE_TYPE).find(place => place[1] === placeType)?.[0] || ''}
                            />
                            <Button variant={searchFilter.isLive ? "contained" : "outlined"} onClick={() => setSearchFilter(prev => ({ ...prev, isLive: !prev.isLive }))}> Live</Button>
                            <Button variant={searchFilter.isActive ? "contained" : "outlined"} onClick={() => setSearchFilter(prev => ({ ...prev, isActive: !prev.isActive }))}> Active</Button>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-1">
                    <h1 className="text-lg font-bold">Search</h1>
                    <div className="flex items-center gap-2">
                        <TextField
                            label="Search"
                            variant="outlined"
                            size="small"
                            onChange={handleSearch}
                        />
                        <FormControlLabel
                            sx={{ m: 0 }}
                            control={<Switch checked={densePadding} onChange={() => {
                                setDensePadding(!densePadding);
                            }} />}
                            label="Dense padding"
                        />
                    </div>
                </div>
                <TableContainer>
                    <Table size={densePadding ? 'small' : 'medium'}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'label'}
                                        direction={orderBy === 'label' ? order : 'asc'}
                                        onClick={(event) => handleRequestSort(event, 'label')}
                                    >
                                        Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'id'}
                                        direction={orderBy === 'id' ? order : 'asc'}
                                        onClick={(event) => handleRequestSort(event, 'id')}
                                    >
                                        Id
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'type'}
                                        direction={orderBy === 'type' ? order : 'asc'}
                                        onClick={(event) => handleRequestSort(event, 'type')}
                                    >
                                        Type
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <div className="flex items-center justify-end ml-auto">
                                        Action
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedRows.map((place, index) => (
                                <TableRow key={index}>
                                    <TableCell>{place.label}</TableCell>
                                    <TableCell>{place.id}</TableCell>
                                    <TableCell className={`${place.type === 'attraction' ? 'font-bold' : ''}`}>{place.type}</TableCell>
                                    <TableCell>
                                        <div className="flex items-center justify-end ml-auto gap-2">
                                            <Button
                                                onClick={() => {
                                                    window.open(`/dashboard/edit-place/${place.id}`, '_blank')
                                                }}
                                                variant="contained"
                                                color="error"
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                href={`/dashboard/edit-place-vibes/${place.id}`}
                                                target="_blank"
                                                variant="contained"
                                                color="error"
                                            >
                                                Vibes
                                            </Button>
                                            <Button
                                                href={`/dashboard/edit-place-dishes/${place.id}`}
                                                target="_blank"
                                                variant="contained"
                                                color="error"
                                            >
                                                Dishes
                                            </Button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 50, 100]}
                    component="div"
                    count={sortedRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            </div>
            {addPlaceOpen && (
                <AddOrEditPlace
                    place={selectedPlace}
                    isOpen={addPlaceOpen}
                    onClose={() => {
                        setSelectedPlace(null);
                        setAddPlaceOpen(false);
                    }}
                />
            )}
        </Container>
    );
}

export default PlacesListPage;
