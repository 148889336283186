/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Container, TextField, Typography, Button, IconButton, Autocomplete } from '@mui/material';
import Page from '../../components/Page';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { API_URL, CREATOR_CHANNEL_TYPE } from 'src/utils/constants';
import RemoveIcon from '@mui/icons-material/Remove';

export default function EditPlaceVibes() {
    const [isLoading, setIsLoading] = useState(false);
    const [urls, setUrls] = useState([{ url: '', source: CREATOR_CHANNEL_TYPE.INSTAGRAM, title: '', tags: [] }]);
    const [vibes, setVibes] = useState([]);
    const [vibeTags, setVibeTags] = useState([]);
    const { slug } = useParams();

    const getPlace = async () => {
        try {
            const res = await axios.get(`${API_URL}/place/mb/getPlaceById/${slug}`);
            setVibes(res.data.vibes || []);
        } catch (error) {
            console.log(error);
        }
    };

    const getVibeTags = async () => {
        const res = await axios.get(`${API_URL}/util/vibe-tags`);
        setVibeTags(res.data.tags || []);
    }

    const handleUrlChange = (index, field, value) => {
        const newUrls = [...urls];
        newUrls[index][field] = value;
        setUrls(newUrls);
    };

    const handleVibePositionChange = (index, value) => {
        const newVibes = [...vibes];
        newVibes[index].position = parseInt(value, 10);
        setVibes(newVibes);
    };

    const addUrlField = () => {
        setUrls([...urls, { url: '', source: CREATOR_CHANNEL_TYPE.INSTAGRAM, title: '', tags: [] }]);
    };

    const removeUrlField = (index) => {
        const newUrls = urls.filter((_, i) => i !== index);
        setUrls(newUrls);
    };

    const removeVibe = (index) => {
        const newVibes = vibes.filter((_, i) => i !== index);
        setVibes(newVibes);
    };

    const submitUrls = async () => {
        if (urls.filter((url) => url.url.length > 0).length === 0) {
            return;
        }
        try {
            setIsLoading(true);
            console.log('Submitted URLs:', urls);
            const response = await axios.post(`${API_URL}/place/mb/${slug}/vibes`, {
                vibes: urls.map(item => ({
                    ...item,
                    tags: item.tags.map(tag => tag.id)
                }))
            });
            if (response.status === 200) {
                location.reload();
                setIsLoading(false);
            } else {
                console.error('Error submitting vibe positions:', response);
            }
        } catch (error) {
            console.error('Error submitting URLs:', error);
        }
    };

    const submitVibePositions = async () => {
        setIsLoading(true);
        try {
            console.log('Submitted Vibe Positions:', vibes);
            const response = await axios.put(`${API_URL}/place/mb/${slug}/vibes`, { placeId: slug.split('-')[0], vibes });
            if (response.status === 200) {
                location.reload();
            } else {
                console.error('Error submitting vibe positions:', response);
            }
        } catch (error) {
            console.error('Error submitting vibe positions:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getPlace();
        getVibeTags();
    }, []);

    return (
        <Page title="Edit Place Vibes">
            <Container>
                <h1 className='text-2xl font-bold'>Edit Place Vibes</h1>
                {urls.map((urlObj, index) => (
                    <div key={index} className="flex items-start gap-2">
                        <div className="flex-1 grid grid-cols-4 gap-2">
                            <TextField
                                label="Title"
                                value={urlObj.title}
                                onChange={(e) => handleUrlChange(index, 'title', e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <Autocomplete
                                options={Object.values(CREATOR_CHANNEL_TYPE)}
                                value={urlObj.source}
                                onChange={(e, newValue) => handleUrlChange(index, 'source', newValue)}
                                renderInput={(params) => <TextField {...params} label="Source" margin="normal" />}
                            />
                            <TextField
                                label={`URL ${index + 1}`}
                                value={urlObj.url}
                                onChange={(e) => handleUrlChange(index, 'url', e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <Autocomplete
                                multiple
                                options={vibeTags}
                                value={urlObj.tags}
                                onChange={(e, newValue) => handleUrlChange(index, 'tags', newValue)}
                                renderInput={(params) => <TextField {...params} label="Tags" margin="normal" />}
                            />
                        </div>
                        <IconButton onClick={() => removeUrlField(index)} color="secondary">
                            <RemoveIcon />
                        </IconButton>
                    </div>
                ))}
                <div className='flex gap-4 items-center justify-between'>
                    <Button variant="contained" onClick={addUrlField}>
                        Add Another URL
                    </Button>
                    <Button disabled={isLoading} variant="contained" color="primary" onClick={submitUrls}>
                        Submit URLs
                    </Button>
                </div>
                <div className='my-5'>
                    <h1 className='text-lg font-bold'>Vibes</h1>
                    <div className='grid grid-cols-3 gap-4'>
                        {vibes.map((vibe, index) => (
                            <div key={vibe._id} className=' border rounded-xl'>
                                <div className='flex items-center justify-between gap-2 px-2'>
                                    <Typography variant="h6">Vibe {vibe.position}</Typography>
                                    <IconButton onClick={() => removeVibe(index)} color="error">
                                        <RemoveIcon />
                                    </IconButton>
                                </div>
                                <video width="320" height="240" controls>
                                    <source src={vibe.url} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <TextField
                                    label="Position"
                                    type="number"
                                    value={vibe.position}
                                    onChange={(e) => handleVibePositionChange(index, e.target.value.replace(/[^0-9]/g, ''))}
                                    fullWidth
                                    margin="normal"
                                />

                            </div>
                        ))}
                    </div>
                    <Button disabled={isLoading} variant="contained" color="secondary" onClick={submitVibePositions}>
                        Update Vibe Positions
                    </Button>
                </div>
            </Container>
        </Page>
    );
}