import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Box, Autocomplete, Typography, IconButton } from '@mui/material';
import { getDish } from './services/getDish';
import { updateDish } from './services/updateDish';
import Page from 'src/components/Page';
import { API_URL, CREATOR_CHANNEL_TYPE } from 'src/utils/constants';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import RemoveIcon from '@mui/icons-material/Remove';

export default function EditDish() {
    const { dishId } = useParams();
    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [urls, setUrls] = useState([]);
    const [vibes, setVibes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [vibeTags, setVibeTags] = useState([]);

    const getVibeTags = async () => {
        const res = await axios.get(`${API_URL}/util/vibe-tags`);
        setVibeTags(res.data.tags || []);
    }

    useEffect(() => {
        const fetchDish = async () => {
            try {
                setIsLoading(true);
                const dish = await getDish(dishId);
                if (dish) {
                    setName(dish.name);
                    setDesc(dish.desc);
                    setVibes(dish.vibes || []);
                }
            } catch (error) {
                console.error('Failed to fetch dish:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchDish();
        getVibeTags();
    }, [dishId]);

    const handleUrlChange = (index, field, value) => {
        const newUrls = [...urls];
        newUrls[index][field] = value;
        setUrls(newUrls);
    };

    const handleVibePositionChange = (index, value) => {
        const newVibes = [...vibes];
        newVibes[index].position = parseInt(value, 10);
        setVibes(newVibes);
    };

    const addUrlField = () => {
        setUrls([...urls, { url: '', source: CREATOR_CHANNEL_TYPE.INSTAGRAM, title: '', tags: [] }]);
    };

    const removeUrlField = (index) => {
        const newUrls = urls.filter((_, i) => i !== index);
        setUrls(newUrls);
    };

    const removeVibe = (index) => {
        const newVibes = vibes.filter((_, i) => i !== index);
        setVibes(newVibes);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const updatedDish = {
            name, desc, vibes, newVibes: urls.filter(url => url.url.length > 0).map(url => ({
                url: url.url,
                source: url.source,
                title: url.title,
                tags: url.tags.map(tag => tag.id)
            }))
        };
        try {
            setIsLoading(true);
            const response = await updateDish(dishId, updatedDish);
            if (response.status === 200) {
                location.reload();
            } else {
                console.error('Failed to update dish:', response);
            }
        } catch (error) {
            console.error('Failed to update dish:', error);
        }
    };

    return (
        <Page title='Edit Dish'>
            <Container>
                <h1 className='text-2xl font-bold'>Edit Dish</h1>
                <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                        label="Name"
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <TextField
                        label="Description"
                        variant="outlined"
                        value={desc}
                        onChange={(e) => setDesc(e.target.value)}
                        required
                    />
                    <h1 className='text-lg font-bold my-3'>Add Vibes</h1>
                    {urls.map((urlObj, index) => (
                        <div key={index} className="flex items-start gap-2">
                            <div className="flex-1 grid grid-cols-4 gap-2">
                                <TextField
                                    label="Title"
                                    variant="outlined"
                                    value={urlObj.title}
                                    onChange={(e) => handleUrlChange(index, 'title', e.target.value)}
                                    required
                                />
                                <Autocomplete
                                    options={Object.values(CREATOR_CHANNEL_TYPE)}
                                    value={urlObj.source}
                                    onChange={(e, newValue) => handleUrlChange(index, 'source', newValue)}
                                    renderInput={(params) => <TextField {...params} label="Source" variant="outlined" />}
                                />
                                <TextField
                                    label={`URL ${index + 1}`}
                                    variant="outlined"
                                    value={urlObj.url}
                                    onChange={(e) => handleUrlChange(index, 'url', e.target.value)}
                                    required
                                />
                                <Autocomplete
                                    multiple
                                    options={vibeTags}
                                    value={urlObj.tags}
                                    onChange={(e, newValue) => handleUrlChange(index, 'tags', newValue)}
                                    renderInput={(params) => <TextField {...params} label="Tags" variant="outlined" />}
                                />
                            </div>
                            <IconButton onClick={() => removeUrlField(index)} color="secondary" disabled={isLoading}>
                                <RemoveIcon />
                            </IconButton>
                        </div>
                    ))}
                    <Button variant="contained" onClick={addUrlField} disabled={isLoading}>
                        Add Another URL
                    </Button>
                    <div className='my-5'>
                        <h1 className='text-lg font-bold'>Vibes</h1>
                        <div className='grid grid-cols-3 gap-4'>
                            {vibes.map((vibe, index) => (
                                <div key={vibe._id} className='border rounded-xl'>
                                    <div className='flex items-center justify-between gap-2 px-2'>
                                        <Typography variant="h6">Vibe {vibe.position}</Typography>
                                        <IconButton onClick={() => removeVibe(index)} color="error" disabled={isLoading}>
                                            <RemoveIcon />
                                        </IconButton>
                                    </div>
                                    <video width="320" height="240" controls>
                                        <source src={vibe.url} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    <TextField
                                        label="Position"
                                        type="number"
                                        value={vibe.position}
                                        onChange={(e) => handleVibePositionChange(index, e.target.value.replace(/[^0-9]/g, ''))}
                                        fullWidth
                                        margin="normal"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
                        Update Dish
                    </Button>
                </Box>
            </Container>
        </Page>
    );
}