/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from 'src/utils/constants';
import { Autocomplete, TextField, Container, Box, Button, List, ListItem, ListItemText, IconButton } from '@mui/material';
import { getDishes } from '../foodDishes/services/getDishes';
import Page from 'src/components/Page';
import RemoveIcon from '@mui/icons-material/Remove';

export default function EditPlaceDishes() {
    const { slug } = useParams();
    const [place, setPlace] = useState(null);
    const [dishes, setDishes] = useState([]);
    const [selectedDishes, setSelectedDishes] = useState([]);
    const [existingDishes, setExistingDishes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getPlace = async () => {
        setIsLoading(true);
        try {
            const res = await axios.get(`${API_URL}/place/mb/getPlaceById/${slug}`);
            setPlace(res.data);
            setExistingDishes(res.data.foodDishes || []);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchDishes = async (name) => {
        try {
            const response = await getDishes({ name });
            setDishes(response);
        } catch (error) {
            console.error('Failed to fetch dishes:', error);
        }
    };

    const addDishesToPlace = async () => {
        setIsLoading(true);
        try {
            const response = await axios.put(`${API_URL}/place/mb/${slug}/foodDishes`, {
                foodDishes: [...existingDishes, ...selectedDishes].map(dish => ({
                    id: dish.id,
                    name: dish.name,
                    displayScore: dish.displayScore || 0,
                })),
            });
            if (response.status === 200) {
                location.reload();
            } else {
                console.error('Failed to add dishes:', response);
            }
        } catch (error) {
            console.error('Error adding dishes:', error);
        }
    };

    const handleSelectedDishChange = (index, field, value) => {
        const newSelectedDishes = [...selectedDishes];
        newSelectedDishes[index][field] = value;
        setSelectedDishes(newSelectedDishes);
    };

    const handleExistingDishChange = (index, field, value) => {
        const newExistingDishes = [...existingDishes];
        newExistingDishes[index][field] = value;
        setExistingDishes(newExistingDishes);
    };


    const removeExistingDish = (index) => {
        const newExistingDishes = existingDishes.filter((_, i) => i !== index);
        setExistingDishes(newExistingDishes);
    };

    useEffect(() => {
        getPlace();
    }, []);

    useEffect(() => {
        if (place && existingDishes && existingDishes.length > 0) {
            const isSorted = existingDishes.every((dish, index) => {
                return index === 0 || parseFloat(existingDishes[index - 1].displayScore) >= parseFloat(dish.displayScore);
            });

            if (!isSorted) {
                const sortedDishes = [...existingDishes].sort((a, b) => parseFloat(b.displayScore) - parseFloat(a.displayScore));
                setExistingDishes(sortedDishes);
            }
        }
    }, [place, existingDishes]); // Ensure sorting only happens when 'place' changes

    return (
        <Page title="Edit Place Dishes">
            <Container>
                {place ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <h1 className='text-2xl font-bold'>Edit Place Dishes for: {place.name}</h1>
                        <Autocomplete
                            options={dishes}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setSelectedDishes((prevSelectedDishes) => [...prevSelectedDishes, { ...newValue, displayScore: 0 }]);
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search Dishes"
                                    onChange={(e) => fetchDishes(e.target.value)}
                                />
                            )}
                        />
                        {selectedDishes.length > 0 && (
                            <h2 className='text-xl font-bold mt-4'>New Dishes</h2>
                        )}
                        <List>
                            {selectedDishes.map((dish, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={dish.name} />
                                    <TextField
                                        label="Display Score"
                                        type="number"
                                        value={dish.displayScore}
                                        onChange={(e) => handleSelectedDishChange(index, 'displayScore', e.target.value)}
                                        sx={{ width: 100, marginLeft: 2 }}
                                    />
                                    <IconButton onClick={() => setSelectedDishes(selectedDishes.filter((_, i) => i !== index))} color="secondary" disabled={isLoading}>
                                        <RemoveIcon />
                                    </IconButton>
                                </ListItem>
                            ))}
                        </List>
                        <h2 className='text-xl font-bold mt-4'>Existing Dishes</h2>
                        <List>
                            {existingDishes.map((dish, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={dish.name} />
                                    <TextField
                                        label="Display Score"
                                        type="number"
                                        value={dish.displayScore}
                                        onChange={(e) => handleExistingDishChange(index, 'displayScore', e.target.value)}
                                        sx={{ width: 100, marginLeft: 2 }}
                                    />
                                    <IconButton onClick={() => removeExistingDish(index)} color="secondary" disabled={isLoading}>
                                        <RemoveIcon />
                                    </IconButton>
                                </ListItem>
                            ))}
                        </List>
                        <Button variant="contained" color="primary" onClick={addDishesToPlace} disabled={isLoading}>
                            Update Dishes to Place
                        </Button>
                    </Box>
                ) : (
                    <p>Loading...</p>
                )}
            </Container>
        </Page>
    );
}