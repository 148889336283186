import { useState } from "react";
import { debounce } from 'lodash';
import axios from "axios";
import { API_URL } from "src/utils/constants";
import { Autocomplete, TextField } from "@mui/material";
import PropTypes from "prop-types";

let cancelToken;

export default function PlacesAutocompleteMultiple({ onChange, label = "Search All Destinations", filters = {}, className = '' }) {
    const [searchPlaces, setSearchPlaces] = useState([]);
    const [placesLoading, setPlacesLoading] = useState(false);
    const [selectedPlaces, setSelectedPlaces] = useState([]);
    const fetchPlaces = debounce(async (query) => {
        try {
            if (cancelToken) {
                cancelToken.cancel();
            }
            cancelToken = axios.CancelToken.source();

            setPlacesLoading(true);
            console.log(filters)
            const response = await axios.get(`${API_URL}/place/mb/search`, {
                params: Object.keys(filters).length > 0 ? { ...filters, query } : { query },
                cancelToken: cancelToken.token,
            });

            setSearchPlaces(
                response.data.map((item) => ({
                    id: item.id,
                    label: item.label,
                }))
            );
            setPlacesLoading(false);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Previous request canceled', error.message);
            } else {
                console.error('Failed to fetch data:', error);
            }
        }
    }, 700);

    return (
        <Autocomplete
            className={className}
            multiple
            id="combo-box-demo"
            options={placesLoading ? [{ id: "Loading...", label: "Loading..." }] : searchPlaces}
            renderInput={(params) => <TextField {...params} label={label} />}
            onInputChange={(_, newInputValue) => {
                if (newInputValue.length > 2) {
                    fetchPlaces(newInputValue);
                }
            }}
            onChange={(_, newValues) => {
                onChange(newValues);
                setSelectedPlaces(newValues);
            }}
            value={selectedPlaces}
        />
    );
}

PlacesAutocompleteMultiple.propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    className: PropTypes.string,
    filters: PropTypes.object,
};