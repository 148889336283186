/* eslint-disable */
import React, { useState, useCallback, useEffect } from 'react';
import { Container, Typography, Autocomplete, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Input, Checkbox } from '@mui/material';
import Page from 'src/components/Page';
import DeleteIcon from '@mui/icons-material/Delete';
import { getAdditionalBlocks } from 'src/pages/Blocks/services/getAdditionalBlocks';
import { createBlock } from 'src/pages/Blocks/services/createBlock';
import PlacesAutocomplete from 'src/components/Places/Autocomplete';
import PlacesAutocompleteMultiple from 'src/components/Places/AutocompleteMultiple';
import { useParams } from 'react-router-dom';
import { getBlock } from './services/getBlock';
import { updateBlock } from './services/updateBlock';
const EditItineraryBlock = () => {
    const { blockId } = useParams()
    const [placeName, setPlaceName] = useState('')
    const [selectedDestination, setSelectedDestination] = useState(null);
    const [pricing, setPricing] = useState([]);
    const [finalPricing, setFinalPricing] = useState([]);
    const [additionalBlocks, setAdditionalBlocks] = useState([]);
    const [selectedAdditionalBlocks, setSelectedAdditionalBlocks] = useState([]);
    const [dayTitle, setDayTitle] = useState('');
    const [selectedAttractions, setSelectedAttractions] = useState([]);
    const [selectedPlaces, setSelectedPlaces] = useState([]);
    const [title, setTitle] = useState('');
    const [itinerary, setItinerary] = useState([]);
    const [loading, setLoading] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [placeAutocompleteFilter, setPlaceAutocompleteFilter] = useState({
        path: selectedDestination?.id || '',
        project: 'addressComponents',
        isLive: true,
        isActive: true
    });
    const [errorMessage, setErrorMessage] = useState(null)
    useEffect(() => {
        setLoading(true)

        getBlock(blockId).then((data) => {
            setPlaceName(data.destinationPlaceName)
            getAdditionalBlocks({ placeId: data.destinationPlaceId, blockType: data.blockType }).then((addBlock) => {
                setAdditionalBlocks(addBlock.filter(item => item.blockType == data.blockType).map(block => ({
                    id: block.id,
                    label: block.title,
                    data: block
                })))
            });
            setPlaceAutocompleteFilter((prev) => ({ ...prev, path: data.destinationPlaceId }))
            setFinalPricing(data.pricingOptions.map(item => ({ finalPrice: item.pricing[0].price, additionalPrice: item.pricing[0].price - item.pricing[0].basePrice })))
            setTitle(data.title)
            setItinerary(data.itinerary.map(iti => ({
                dayTitle: iti.title,
                attractions: iti.attractions.map(attr => ({
                    id: attr.placeId._id,
                    label: attr.placeId.name,
                    isIncluded: attr.isIncluded,
                    info: attr.info
                })),
                places: iti.placeIds.map(place => ({
                    id: place._id,
                    label: place.name
                }))
            })))
            setPricing(data.pricingOptions?.map(item => ({
                variantTitle: item.variantId.title,
                variantDesc: item.variantId.desc || '',
                variantId: item.variantId._id,
                pricing: item.pricing.map(price => ({
                    pricingId: price.pricingId,
                    price: 0,
                    basePrice: price.basePrice
                })),
                additionalBlockId: []
            })))
            setSelectedAdditionalBlocks(data.pricingOptions[0].additionalBlockId.map(addBlk => {
                return ({
                    id: addBlk._id,
                    label: addBlk.title,
                    data: addBlk,
                })
            }))

        }).finally(setLoading(false));
    }, []);




    useEffect(() => {
        if (selectedAdditionalBlocks.length > 0) {
            let newFinalPricing = finalPricing.map(item => ({ finalPrice: item.finalPrice, additionalPrice: 0 }))
            selectedAdditionalBlocks.forEach((additionalBlock) => {
                additionalBlock.data.pricingOptions.forEach((price) => {
                    const variantIndex = pricing.findIndex(variant => typeof price.variantId === 'string' ? variant.variantId === price.variantId : variant.variantId === price.variantId._id);
                    if (variantIndex > -1) {
                        if (newFinalPricing[variantIndex].additionalPrice) {
                            newFinalPricing[variantIndex].additionalPrice += parseInt(price.pricing[0].price);
                        } else {
                            newFinalPricing[variantIndex].additionalPrice = parseInt(price.pricing[0].price);
                        }
                    }
                })
            })
            setFinalPricing(newFinalPricing)
            setPricing(prev => prev.map(item => ({ ...item, additionalBlockId: selectedAdditionalBlocks.map(block => block.id) })))
        } else {
            setFinalPricing(prev => prev.map(item => ({ finalPrice: item.finalPrice, additionalPrice: 0 })))
            setPricing(prev => prev.map(item => ({ ...item, additionalBlockId: [] })))
        }
    }, [selectedAdditionalBlocks]);


    useEffect(() => {
        if (pricing.length > 0) {
            pricing.forEach((variant, index) => {
                const totalPrice = variant.pricing.reduce((acc, price) => acc + price.basePrice, 0);
                setFinalPricing(prevPricing => {
                    const newPricing = [...prevPricing];
                    newPricing[index].finalPrice = parseInt(totalPrice || 0);
                    return newPricing;
                });
            });
        }
    }, [pricing]);



    const handleAddItinerary = () => {
        if (dayTitle
            // && selectedAttractions.length
            && selectedPlaces.length) {
            setItinerary(prevItinerary => [
                ...prevItinerary,
                { dayTitle, attractions: selectedAttractions.map(item => ({ ...item, isIncluded: false, info: "" })), places: selectedPlaces }
            ]);
            setDayTitle('');
            setSelectedAttractions([]);
            setSelectedPlaces([]);
        }
    };

    const handleRemoveItinerary = (index) => {
        setItinerary(prevItinerary => prevItinerary.filter((_, i) => i !== index));
    };

    const handleBasePriceChange = (value, index) => {
        setPricing(prevPricing => {
            const newPricing = [...prevPricing];
            newPricing[index].pricing[0].basePrice = parseInt(value || 0);
            return newPricing;
        });
    };

    const handleUpdate = async () => {
        const data = {
            title: title,
            itinerary: itinerary.map(item => ({
                placeIds: item.places.map(place => place.id),
                attractions: item.attractions.map(attr => ({
                    placeId: attr.id,
                    isIncluded: attr.isIncluded,
                    info: attr.info
                })),
                title: item.dayTitle,
            })),
            pricingOptions: pricing.map((variant, variantIndex) => ({
                variantId: variant.variantId,
                pricing: variant.pricing.map((price, index) => ({
                    pricingId: price.pricingId,
                    price: parseInt(finalPricing[variantIndex].finalPrice + finalPricing[variantIndex].additionalPrice),
                    basePrice: parseInt(price.basePrice),
                })),
                additionalBlockId: variant.additionalBlockId,
            })),
        };
        // if (selectedDestination != null && selectedDestination?.id,
        //     data.destinationPlaceId = selectedDestination?.id
        // )
        setSubmitLoading(true)
        await updateBlock(blockId, data).then(res => {
            if (res?.status && res?.status === 200) {
                location.reload();
            } else {
                setErrorMessage(res)
                console.log("Error creating block", res)
            }
        }).finally(() => setSubmitLoading(false))
    };

    const handleAttractionUpdate = (index, index2, key, value) => {
        setItinerary(prev => {
            const newItinerary = [...prev];
            const newAttractions = [...newItinerary[index].attractions];
            newAttractions[index2] = { ...newAttractions[index2], [key]: value };
            newItinerary[index] = { ...newItinerary[index], attractions: newAttractions };
            return newItinerary;
        });
    };

    if (loading) return (
        <Page title="Edit Block itinerary" >
            <Container>
                <Typography variant="h6" sx={{ mb: 2 }}>Loading...</Typography>
            </Container>
        </Page>)

    return (
        <Page title={`${title} - Edit Block itinerary`}>
            <Container>
                <Typography variant="h6" sx={{ mb: 2 }}>Edit Itinerary Block</Typography>
                <div className="mb-3 flex items-center gap-4">
                    <p className='font-bold'>{placeName}</p>
                    <PlacesAutocomplete
                        label="Change Destination"
                        onChange={(newValue) => {
                            setSelectedDestination(newValue)
                        }}
                        className='w-96'
                    />
                </div>
                <div className='flex flex-col gap-2'>
                    <label htmlFor="title" className='text-sm font-bold'>Title</label>
                    <Input
                        placeholder="Itinerary Title"
                        name="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>
                <div className='mt-10 mb-4'>
                    <h1 className='text-lg font-bold'>Add itinerary day</h1>
                    <div className="mb-3 flex items-end justify-evenly w-full gap-4">
                        <div className='w-full flex flex-col gap-2'>
                            <Input
                                className='w-full'
                                placeholder="Day Title"
                                value={dayTitle}
                                onChange={(e) => setDayTitle(e.target.value)}
                            />
                        </div>
                        <PlacesAutocompleteMultiple
                            className='w-full'
                            onChange={(newValue) => {
                                setSelectedAttractions(newValue)
                            }}
                            label="Attractions"
                            filters={{
                                ...placeAutocompleteFilter,
                                types: 'attraction,food,shopping',
                            }}
                        />
                        <PlacesAutocompleteMultiple
                            className='w-full'
                            onChange={(newValue) => {
                                setSelectedPlaces(newValue)
                            }}
                            label="Places"
                            filters={placeAutocompleteFilter}
                        />
                        <Button variant="contained" onClick={handleAddItinerary}>Add</Button>
                    </div>
                </div>
                <TableContainer component={Paper} className='mt-10 mb-4 p-4'>
                    <h1 className='text-lg font-bold'>Itinerary</h1>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Title</TableCell>
                                <TableCell>Attractions</TableCell>
                                <TableCell>Places</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {itinerary.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item.dayTitle}</TableCell>
                                    <TableCell>
                                        {(item.attractions && item.attractions.length > 0) ? <div className='flex flex-col gap-2'>
                                            <div className='flex gap-5 font-bold'>
                                                <span className='w-16'>Included</span>
                                                <span className='w-56'>Attraction</span>
                                                <span>Info</span>
                                            </div>
                                            {item.attractions.map((attr, i) => (
                                                <div className='flex items-center gap-5' key={i}>
                                                    <div className='min-w-16'                                                    >
                                                        <Checkbox
                                                            onClick={() => handleAttractionUpdate(index, i, 'isIncluded', !attr.isIncluded)}
                                                            checked={attr.isIncluded}
                                                        />
                                                    </div>
                                                    <span className='w-full max-w-56 truncate'>{attr.label}</span>
                                                    <Input
                                                        onChange={(e) => handleAttractionUpdate(index, i, 'info', e.target.value)}
                                                        value={attr.info}
                                                        placeholder="Info"
                                                    />
                                                </div>
                                            ))}
                                        </div> : <div>No Attractions</div>}
                                    </TableCell>
                                    <TableCell>
                                        <div className='flex flex-wrap gap-1'>
                                            {item.places.map((place, i) => (
                                                <span key={i} className='bg-gray-200 rounded px-2 py-1'>{place.label}</span>
                                            ))}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleRemoveItinerary(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className='mt-10 mb-4'>
                    <h1 className='text-lg font-bold mb-1'>Pricing</h1>
                    <div className='flex flex-col gap-2 mb-4'>
                        <label htmlFor="additionalBlocks" className='text-sm font-bold'>Additional Blocks</label>
                        <Autocomplete
                            options={additionalBlocks}
                            getOptionLabel={(option) => option.label || ''}
                            renderInput={(params) => <TextField {...params} label="Additional Blocks" />}
                            value={selectedAdditionalBlocks}
                            onChange={(_, newValue) => { if (newValue) setSelectedAdditionalBlocks(prev => [...prev, newValue]) }}
                        />
                    </div>
                    <div>
                        <h1 className='font-semibold'>Selected Blocks</h1>
                        {selectedAdditionalBlocks.map((item, index) => <div className='flex items-center justify-between' key={`${item.id}-${index}`}>
                            <p className='text-sm'>{item.label}</p>
                            <IconButton onClick={() => setSelectedAdditionalBlocks(prev => prev.filter((data, ind) => ind !== index))}>
                                <DeleteIcon />
                            </IconButton>
                        </div>)}
                    </div>
                    <div className='my-2 w-full h-px bg-black'></div>
                    <div className="">{
                        pricing?.length > 0 &&
                        <div className="grid grid-cols-1 gap-4">
                            {pricing.map((variant, index) => (
                                <div className="grid grid-cols-3 gap-4" key={index}>
                                    <div className='flex flex-col'>
                                        <h1 className='text-lg font-bold'>{variant.variantTitle}</h1>
                                        <p>{variant.variantDesc}</p>
                                    </div>
                                    <Input
                                        onChange={(e) => handleBasePriceChange(e.target.value.replace(/[^0-9]/g, ''), index)}
                                        placeholder='Base Price' value={variant.pricing[0].basePrice} />
                                    <div className='flex items-center gap-2'>
                                        <p>Price</p>
                                        <p>{finalPricing[index]?.finalPrice + finalPricing[index]?.additionalPrice || 0} </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }</div>
                </div>
                {errorMessage && errorMessage.length > 0 && <p className="text-red-500">{errorMessage}</p>}
                <Button disabled={submitLoading} onClick={handleUpdate} variant="contained" sx={{ mt: 2 }}>Update</Button>
            </Container>
        </Page >
    );
};

export default EditItineraryBlock;
