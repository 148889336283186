import axios from "axios";
import { API_URL } from "../../../utils/constants";

export const createBlock = async (data) => {
    try {
        const response = await axios.post(`${API_URL}/blocks/create`, data);
        return response;
    } catch (error) {
        console.log(error);
    }
}
