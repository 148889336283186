import { Autocomplete, Box, Container, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "src/utils/constants";
import { ActivityBanners } from "src/components/banners/activityBanners";
import Page from "src/components/Page";
import { useParams } from 'react-router-dom';



const booleanSelect = [
    'true',
    'false',
]

const placeStatus = [
    'soft-deleted',
]
export const EditPlace = () => {
    const { slug } = useParams();

    const [placeType, setPlaceType] = useState([]);
    const [newPlaceType, setNewPlaceType] = useState('');
    const [newPlace, setNewPlace] = useState({
        name: '',
        description: '',
        path: '',
        pathLabel: '',
        thumbnails: [],
        pthumbnails: [],
        type: '',
        // status: null,
        locationCoordinateType: 'Point',
        longitude: '',
        latitude: '',
        isActive: true,
        isLive: false
    });
    const [newPlaceId, setNewPlaceId] = useState('');
    const [initialUrls, setInitialUrls] = useState({});
    const [thumbnail, setThumbnail] = useState({});
    const [loading, setLoading] = useState(false);
    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'latitude' || name === 'longitude') {
            const numValue = value.replace(/[^0-9.+-]/g, '');
            setNewPlace((prev) => ({ ...prev, [name]: numValue }));
        } else {
            setNewPlace((prev) => ({ ...prev, [name]: value }));
        }
    };
    useEffect(() => {
        if (slug) {
            axios
                .get(`${API_URL}/place/mb/getPlaceById/${slug}`)
                .then((res) => {
                    const { id, name, thumbnails, pthumbnails, description, location, path, isActive, isLive, type, address } = res.data;
                    setInitialUrls({ thumbnails: thumbnails.map(item => item.src), pthumbnails: pthumbnails.map(item => item.src) });
                    setNewPlace((prev) => ({
                        ...prev,
                        id,
                        name,
                        description,
                        path,
                        type,
                        address,
                        pathLabel: '',
                        thumbnails,
                        pthumbnails,
                        locationCoordinateType: location?.type || 'Point',
                        longitude: location?.coordinates[0] || '',
                        latitude: location?.coordinates[1] || '',
                        isActive: isActive || false,
                        isLive: isLive || false
                    }));
                })
                .catch((error) => {
                    console.log(error);
                });
            // const getPlace = async () => {
            //     try {
            //         const res = await axios.get(`${API_URL}/place/mb/getPlaceById/${slug}`);
            //         setNewPlace(res.data || []);
            //     } catch (error) {
            //         console.log(error);
            //     }
            // };
        }
        axios.get(`${API_URL}/place/mb/placeTypes`)
            .then((res) => {
                setPlaceType(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    useEffect(() => {
        setNewPlace((prev) => ({ ...prev, thumbnails: thumbnail.thumbnail, pthumbnails: thumbnail.pthumbnail }));
    }, [thumbnail]);


    const handleUpdate = async () => {
        try {
            setLoading(true)
            const updatePlace = {
                ...newPlace,
                description: newPlace?.description?.trim() || '',
                location: {
                    type: newPlace.locationCoordinateType,
                    coordinates: [parseFloat(newPlace.longitude), parseFloat(newPlace.latitude)],
                }
            }
            updatePlace.latitude = undefined
            updatePlace.longitude = undefined
            updatePlace.locationCoordinateType = undefined
            updatePlace.pathLabel = undefined
            const response = await fetch(`${API_URL}/place/${updatePlace.id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...updatePlace }),
            });
            if (response.status === 200) {
                location.reload();
            } else {
                console.error('Failed to submit place:', response.statusText);
            }
        } catch (error) {
            console.error('Error submitting place:', error);
        }
        setLoading(false)
    };
    const handleCreate = async () => {
        setLoading(true)
        const createPlace = {
            ...newPlace,
            type: newPlace.type.id,
            name: newPlace.name.trim() || '',
            description: newPlace.description.trim() || '',
            location: {
                type: newPlace.locationCoordinateType,
                coordinates: [parseFloat(newPlace?.longitude || '0'), parseFloat(newPlace?.latitude || '0')],
            }
        }
        createPlace.latitude = undefined
        createPlace.longitude = undefined
        createPlace.locationCoordinateType = undefined
        createPlace.pathLabel = undefined

        try {
            const response = await fetch(`${API_URL}/place`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...createPlace }),
            });
            if (response.ok) {
                location.reload();
            } else {
                console.error('Failed to submit place:', response.statusText);
            }
        } catch (error) {
            console.error('Error submitting place:', error);
        }
        setLoading(false)
    };
    const handleBannerChange = (event) => {
        const { name, value } = event.target;
        setThumbnail((prev) => ({ ...prev, [name]: value }));
    };

    const [placeId, setPlaceId] = useState('');
    const [searchPlaces, setSearchPlaces] = useState([]);

    const fetchPlaces = async (query) => {
        try {
            const response = await axios.get(`${API_URL}/place/mb/search?query=${query}`);
            setSearchPlaces(response.data.map(item => ({
                id: item.id,
                label: item.label
            })));
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };

    const handlePlaceHierarchy = async () => {
        setLoading(true)
        try {
            if (newPlaceId && newPlaceId.length > 0) {
                const response = await axios.put(`${API_URL}/place/mb/${newPlace.id}/path`, {
                    newPlaceId: newPlaceId,
                });
                if (response.status === 200) {
                    location.reload();
                } else {
                    console.error('Failed to submit place:', response);
                }
            }
        } catch (error) {
            console.error('Error submitting place:', error);
        }
        setLoading(false)
    }
    const handlePlaceType = async () => {
        setLoading(true)
        try {
            if (newPlace.id && newPlaceType && newPlaceType.length > 0) {
                const response = await axios.put(`${API_URL}/place/mb/${newPlace.id}/type`, {
                    type: newPlaceType,
                });
                if (response.status === 200) {
                    location.reload();
                } else {
                    console.error('Failed to submit place:', response);
                }
            }
        } catch (error) {
            console.error('Error submitting place:', error);
        }
        setLoading(false)
    }
    return (
        <Page title="Edit Place">
            <Container>
                <h1 className='text-2xl font-bold mb-4'>Edit Place</h1>
                <Box
                    className=' flex flex-col py-6 p-4 bg-white rounded-lg w-full'>
                    <label className="font-bold">Name</label>
                    <input className="min-h-[45px]" type="text" name="name" placeholder="Place name" value={newPlace.name} onChange={handleChange} />
                    <label className="font-bold">Description</label>
                    <textarea className="min-h-[100px] border-2" type="text" name="description" placeholder="Place description" value={newPlace.description} onChange={handleChange} />
                    {/* Path */}
                    {/* <Autocomplete
                    disabled={place ? true : false}
                    id="combo-box-demo"
                    options={places}
                    // sx={{ width: 340 }}
                    renderInput={(params) => <TextField {...params} label="Location Path" />}
                    onChange={(_, newValue) => {
                        if (newValue) {
                            setNewPlace(prev => ({ ...prev, path: newValue.path, pathLabel: newValue.label }));
                        } else {
                            setNewPlace(prev => ({ ...prev, path: '', pathLabel: '' }));
                        }
                    }}
                    value={newPlace.pathLabel}
                /> */}
                    {<label className="font-bold">Path</label>}
                    {!slug ? <Autocomplete
                        disabled={slug ? true : false}
                        id="combo-box-demo"
                        options={searchPlaces}
                        sx={{ width: 340 }}
                        renderInput={(params) => <TextField {...params} label="Place" />}
                        onInputChange={(_, newInputValue) => {
                            if (newInputValue.length > 0) {
                                fetchPlaces(newInputValue);
                            }
                        }}
                        onChange={(_, newValue) => {
                            if (newValue) {
                                setNewPlace(prev => ({ ...prev, path: newValue.id, pathLabel: newValue.label }));
                                setPlaceId(newValue?.id || '');
                            } else {
                                setNewPlace(prev => ({ ...prev, path: '', pathLabel: '' }));
                                setPlaceId('');
                            }
                        }}
                        value={searchPlaces.find(place => place.id === placeId)?.label || ''}
                    /> :
                        <div className="flex flex-col gap-2">
                            <div className="flex">
                                {newPlace?.address?.components?.map((item, index) => (
                                    <div key={index} className="mx-1 space-x-1 flex">
                                        <h1>{item.name}</h1>
                                        {index < newPlace?.address?.components?.length - 1 && <p>-</p>}
                                    </div>
                                ))}
                            </div>
                            <div className="flex items-center gap-2">

                                <Autocomplete
                                    id="combo-box-demo"
                                    options={searchPlaces}
                                    sx={{ width: 340 }}
                                    renderInput={(params) => <TextField {...params} label="Place" />}
                                    onInputChange={(_, newInputValue) => {
                                        if (newInputValue.length > 0) {
                                            fetchPlaces(newInputValue);
                                        }
                                    }}
                                    onChange={(_, newValue) => {
                                        if (newValue) {
                                            setNewPlaceId(newValue.id);
                                        } else {
                                            setNewPlaceId('');
                                        }
                                    }}
                                    value={searchPlaces.find(place => place.id === newPlaceId)?.label || ''}
                                />
                                <button
                                    disabled={loading}
                                    onClick={handlePlaceHierarchy}
                                    className="bg-[#FF455B] hover:bg-[#da4848] text-white font-extralight p-1.5 px-3 rounded-lg whitespace-nowrap disabled:opacity-50 disabled:cursor-not-allowed">Update Place Hierarchy</button>
                            </div>
                        </div>
                    }
                    {/* Type */}
                    <label className="font-bold">Type</label>
                    {!slug ? <Autocomplete
                        id="combo-box-demo"
                        options={placeType}
                        sx={{ width: 340 }}
                        renderInput={(params) => <TextField {...params} label="Location Type" />}
                        onChange={(_, newValue) => {
                            if (newValue) {
                                setNewPlace(prev => ({ ...prev, type: newValue }));
                            } else {
                                setNewPlace(prev => ({ ...prev, type: '' }));
                            }
                        }}
                        value={placeType.find(type => type.id === newPlace.type)?.label || newPlace.type}
                    /> :
                        <div className="flex items-center gap-2">
                            <h1>{newPlace?.type}</h1>
                            <Autocomplete
                                id="combo-box-demo"
                                options={placeType}
                                sx={{ width: 340 }}
                                renderInput={(params) => <TextField {...params} label="Location Type" />}
                                onChange={(_, newValue) => {
                                    if (newValue) {
                                        setNewPlaceType(newValue.id);
                                    } else {
                                        setNewPlaceType('');
                                    }
                                }}
                                value={placeType.find(type => type.id === newPlaceType)?.label || newPlaceType}
                            />
                            <button
                                disabled={loading}
                                onClick={handlePlaceType}
                                className="bg-[#FF455B] hover:bg-[#da4848] text-white font-extralight p-1.5 px-3 rounded-lg whitespace-nowrap disabled:opacity-50 disabled:cursor-not-allowed">Update Place Type</button>
                        </div>}
                    {/* Status */}
                    <label className="font-bold">Status</label>
                    <Autocomplete
                        disabled={slug ? true : false}
                        id="combo-box-demo"
                        options={placeStatus}
                        // sx={{ width: 340 }}
                        renderInput={(params) => <TextField {...params} label="Location Type" />}
                        onChange={(_, newValue) => {
                            if (newValue) {
                                setNewPlace(prev => ({ ...prev, status: newValue }));
                            } else {
                                setNewPlace(prev => ({ ...prev, status: null }));
                            }
                        }}
                        value={newPlace.status}
                    />
                    <div className="grid grid-cols-2 gap-4">
                        <label className="font-bold">Is Active</label>
                        <label className="font-bold">Is Live</label>
                        <Autocomplete
                            id="combo-box-demo"
                            options={booleanSelect}
                            // sx={{ width: 340 }}
                            renderInput={(params) => <TextField {...params} label="Is Active" />}
                            onChange={(_, newValue) => {
                                if (newValue) {
                                    handleChange({ target: { name: 'isActive', value: newValue } });
                                } else {
                                    handleChange({ target: { name: 'isActive', value: false } });
                                }
                            }}
                            value={newPlace.isActive}
                        />
                        <Autocomplete
                            id="combo-box-demo"
                            options={booleanSelect}
                            // sx={{ width: 340 }}
                            renderInput={(params) => <TextField {...params} label="Is Live" />}
                            onChange={(_, newValue) => {
                                if (newValue) {
                                    handleChange({ target: { name: 'isLive', value: newValue } });
                                } else {
                                    handleChange({ target: { name: 'isLive', value: false } });
                                }
                            }}
                            value={newPlace.isLive}
                        />


                    </div>
                    <div className="grid grid-cols-3 gap-4">
                        <label className="font-bold">Coordinate type</label>
                        <label className="font-bold">latitude</label>
                        <label className="font-bold">longitude</label>
                        <input type="text" name="locationCoordinateType" placeholder="Location Coordinate Type" value={newPlace.locationCoordinateType} disabled={true} />
                        <input type="text" name="latitude" placeholder="Latitude" value={newPlace.latitude} onChange={handleChange} />
                        <input type="text" name="longitude" placeholder="Longitude" value={newPlace.longitude} onChange={handleChange} />
                    </div>
                    <div className="grid grid-cols-4 gap-4 mt-4">
                        <div className="grid grid-cols-1">
                            <label className=" font-bold -p-10">Thumbnail</label>
                            <ActivityBanners handleActivityBannerChange={(banners) => {
                                handleBannerChange({ target: { name: 'thumbnail', value: banners.map(item => ({ src: item })) } });
                            }}
                                initialUrls={initialUrls?.thumbnails && initialUrls?.thumbnails.length > 0 ? initialUrls?.thumbnails.join('\n') : null}
                            />
                        </div>
                        {/* Thumbnails */}
                        <div className="grid grid-cols-1 justify-items-start">
                            <label className=" font-bold -p-10">Action URL</label>
                            {newPlace.thumbnails?.map((item, index) => (
                                <div className="flex items-center gap-2" key={`${item.src}+${index}`}>
                                    <p>{index + 1}</p>
                                    <input
                                        value={item.actionUrl}
                                        onChange={(e) => {
                                            const changedBanner = newPlace.thumbnails[index];
                                            changedBanner.actionUrl = e.target.value;
                                            handleChange({ target: { name: 'thumbnails', value: newPlace.thumbnails.map((item, index) => item === index ? changedBanner : item) } });
                                        }}
                                        className="w-full" type="text" /></div>
                            ))}
                        </div>
                        <div className="grid grid-cols-1">
                            <label className=" font-bold -p-10">Phone Thumbnail</label>
                            <ActivityBanners handleActivityBannerChange={(banners) => {
                                handleBannerChange({ target: { name: 'pthumbnail', value: banners.map(item => ({ src: item })) } });
                            }}
                                initialUrls={initialUrls?.pthumbnails && initialUrls?.pthumbnails?.length > 0 ? initialUrls?.pthumbnails.join('\n') : null}
                            />
                        </div>
                        <div className="grid grid-cols-1 justify-items-start">
                            <label className=" font-bold -p-10">PhoneAction URL</label>
                            {newPlace.pthumbnails?.map((item, index) => (
                                <div className="flex items-center gap-2" key={`${item.src}-${index}`}>
                                    <p>{index + 1}</p>
                                    <input
                                        value={item.actionUrl}
                                        onChange={(e) => {
                                            const changedBanner = newPlace.pthumbnails[index];
                                            changedBanner.actionUrl = e.target.value;
                                            handleChange({ target: { name: 'pthumbnails', value: newPlace.pthumbnails.map((item, index) => item === index ? changedBanner : item) } });
                                        }}
                                        className="w-full" type="text" /></div>
                            ))}
                        </div>
                    </div>

                    {/* <input type="text" name="path" placeholder="Path" value={newPlace.path} onChange={handleChange} /> */}
                    <div className="col-span-2 flex items-end gap-4 mt-4">
                        {slug ?
                            (<button
                                disabled={loading}
                                onClick={handleUpdate}
                                className="bg-[#FF455B] hover:bg-[#da4848] text-white font-extralight p-1.5 px-3 rounded-lg whitespace-nowrap disabled:opacity-50 disabled:cursor-not-allowed"
                            >Update</button>) :
                            (<button
                                disabled={loading}
                                onClick={handleCreate}
                                className="bg-[#FF455B] hover:bg-[#da4848] text-white font-extralight p-1.5 px-3 rounded-lg whitespace-nowrap disabled:opacity-50 disabled:cursor-not-allowed"
                            >Create</button>)
                        }
                    </div>
                </Box>
            </Container>
        </Page>
    );
};
