import axios from 'axios';
import { API_URL } from '../../../utils/constants';
export const getBlockVariants = async (destinationBlockId) => {
    try {
        const response = await axios.get(`${API_URL}/blocks/blockVariants/${destinationBlockId}`);
        return response.data.blocks || []
    } catch (error) {
        console.error('Error fetching block variants:', error);
        return [];
    }
};
