import React, { useEffect, useState, useMemo } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    IconButton,
    TextField,
    Menu,
    MenuItem,
    FormControlLabel,
    Switch,
    TablePagination,
    Button,
    Checkbox,
    Box,
    Container,
    Chip,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getDishes } from './services/getDishes';
import { Link } from 'react-router-dom';
import Page from 'src/components/Page';
import PageHeading from 'src/components/PageHeading';
import { Loading } from 'src/components/Loading';

const columns = [
    { id: 'name', label: 'Name', sortable: true, searchable: true },
    { id: 'desc', label: 'Description', sortable: true, searchable: true },
    { id: 'vibes', label: 'Vibes', sortable: false, searchable: false },
    { id: 'edit', label: 'Edit', sortable: false, searchable: false },
];

const createData = (id, name, desc, vibes) => {
    return { id, name, desc, vibes };
};

export default function ListDishes() {
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [visibleColumnIds, setVisibleColumnIds] = useState(columns.map((col) => col.id));
    const [columnOrder, setColumnOrder] = useState(columns.map((col) => col.id));
    const [searchTerm, setSearchTerm] = useState('');
    const [dense, setDense] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const data = await getDishes();
            const formattedData = data.map((row) => createData(
                row.id,
                row.name,
                row.desc,
                row.vibes,
            ));
            setRows(formattedData);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Failed to fetch data:', error);
        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const handleColumnVisibility = (id) => {
        setVisibleColumnIds((prev) => {
            const newColumnIds = prev.includes(id)
                ? prev.filter((colId) => colId !== id)
                : [...prev, id].sort((a, b) => columns.findIndex(col => col.id === a) - columns.findIndex(col => col.id === b));
            setColumnOrder(newColumnIds);
            return newColumnIds;
        });
    };

    const handleDenseChange = (event) => {
        setDense(event.target.checked);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const filteredRows = useMemo(() => {
        return rows.filter((row) => {
            return Object.keys(row).some(
                (key) => row[key]?.toString().toLowerCase().includes(searchTerm)
            );
        });
    }, [searchTerm, rows]);

    const sortedRows = useMemo(() => {
        return filteredRows.sort((a, b) => {
            let aValue = a[orderBy];
            let bValue = b[orderBy];

            aValue = aValue?.toString().toLowerCase();
            bValue = bValue?.toString().toLowerCase();

            if (aValue < bValue) return order === 'asc' ? -1 : 1;
            if (aValue > bValue) return order === 'asc' ? 1 : -1;
            return 0;
        });
    }, [filteredRows, orderBy, order]);

    const paginatedRows = useMemo(() => {
        return sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }, [sortedRows, page, rowsPerPage]);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Page title="Dishes List">
            <Container>
                <PageHeading heading="Dishes List" />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 4, gap: 2 }}>
                    <TextField
                        label="Search"
                        variant="outlined"
                        size="small"
                        onChange={handleSearch}
                    />
                    <FormControlLabel
                        sx={{ m: 0 }}
                        control={<Switch checked={dense} onChange={handleDenseChange} />}
                        label="Dense padding"
                    />
                    <IconButton
                        aria-label="settings"
                        onClick={handleMenuClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        {columns.map((column) => (
                            <MenuItem key={column.id} onClick={() => handleColumnVisibility(column.id)}>
                                <Checkbox
                                    checked={visibleColumnIds.includes(column.id)}
                                />
                                {column.label}
                            </MenuItem>
                        ))}
                    </Menu>
                    <Button target='_blank' variant='contained' color="error" component={Link} to="/dashboard/foodDishes/add">
                        Add Dish
                    </Button>
                </Box>

                <TableContainer>
                    <Table size={dense ? 'small' : 'medium'}>
                        <TableHead>
                            <TableRow>
                                {columnOrder.map((colId) => {
                                    const column = columns.find((col) => col.id === colId);
                                    if (!column || !visibleColumnIds.includes(column.id)) return null;
                                    return (
                                        <TableCell key={column.id}>
                                            {column.sortable ? (
                                                <TableSortLabel
                                                    active={orderBy === column.id}
                                                    direction={orderBy === column.id ? order : 'asc'}
                                                    onClick={(event) => handleRequestSort(event, column.id)}
                                                >
                                                    {column.label}
                                                </TableSortLabel>
                                            ) : (
                                                column.label
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedRows.map((row, index) => (
                                <TableRow key={index} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                                    {columnOrder.map((colId) => {
                                        const column = columns.find((col) => col.id === colId);
                                        if (!column || !visibleColumnIds.includes(column.id)) return null;
                                        if (column.id === 'vibes') {
                                            return (
                                                <TableCell key={column.id}>
                                                    <div className="flex flex-wrap gap-2">
                                                        {row[column.id].map((vibe) => (
                                                            <Chip label={vibe.title} key={vibe.id} />
                                                        ))}
                                                    </div>
                                                </TableCell>
                                            );
                                        }
                                        if (column.id === 'edit') {
                                            console.log(row);
                                            return (
                                                <TableCell key={column.id}>
                                                    <Link target='_blank' to={`/dashboard/foodDishes/edit/${row.id}`}>
                                                        <Button variant="contained" color="primary">
                                                            Edit
                                                        </Button>
                                                    </Link>
                                                </TableCell>
                                            );
                                        }
                                        return (
                                            <TableCell key={column.id}>{row[column.id]}</TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 50, 100]}
                    component="div"
                    count={sortedRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            </Container>
        </Page>
    );
}