import axios from 'axios';
import { API_URL } from '../../../utils/constants';
export const getdestinationBlockTypes = async () => {
    try {

        const response = await axios.get(`${API_URL}/blocks/destinationBlockTypes`);
        return response.data.blocks || []
    } catch (error) {
        console.error('Error fetching destination blocks:', error);
        return [];
    }
};