import axios from 'axios';
import { API_URL } from 'src/utils/constants';

export const createDish = async (data) => {
    try {
        const response = await axios.post(`${API_URL}/foodDishes`, data);
        return response
    } catch (error) {
        console.error('Error creating dish:', error);
        throw error;
    }
};
