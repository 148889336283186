import axios from 'axios';
import { API_URL } from 'src/utils/constants';

export const getDish = async (dishId) => {
    try {
        const response = await axios.get(`${API_URL}/foodDishes/${dishId}`);
        return response.data.dish || null;
    } catch (error) {
        console.error('Error fetching dish:', error);
        return null;
    }
};
