import axios from 'axios';
import { API_URL } from 'src/utils/constants';

export const updateDish = async (dishId, updatedData) => {
    try {
        const response = await axios.put(`${API_URL}/foodDishes/${dishId}`, updatedData);

        return response;
    } catch (error) {
        console.error("Error updating dish:", error);
        throw error;
    }
};
