import axios from 'axios';
import { API_URL } from 'src/utils/constants';

export const getDishes = async (query) => {
    try {
        const response = await axios.get(`${API_URL}/foodDishes`, {
            params: { query }
        });
        return response.data.dishes || [];
    } catch (error) {
        console.error('Error fetching dishes:', error);
        return [];
    }
}