import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Box, Autocomplete } from '@mui/material';
import { createDish } from './services/createDish';
import Page from 'src/components/Page';
import { API_URL, CREATOR_CHANNEL_TYPE } from 'src/utils/constants';
import axios from 'axios';

export default function AddDish() {
    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [urls, setUrls] = useState([{ url: '', source: CREATOR_CHANNEL_TYPE.INSTAGRAM, title: '', tags: [] }]);
    const [isLoading, setIsLoading] = useState(false);
    const [vibeTags, setVibeTags] = useState([]);

    const getVibeTags = async () => {
        const res = await axios.get(`${API_URL}/util/vibe-tags`);
        setVibeTags(res.data.tags || []);
    }

    useEffect(() => {
        getVibeTags();
    }, []);

    const handleUrlChange = (index, field, value) => {
        const newUrls = [...urls];
        newUrls[index][field] = value;
        setUrls(newUrls);
    };

    const addUrlField = () => {
        setUrls([...urls, { url: '', source: CREATOR_CHANNEL_TYPE.INSTAGRAM, title: '', tags: [] }]);
    };

    const removeUrlField = (index) => {
        const newUrls = urls.filter((_, i) => i !== index);
        setUrls(newUrls);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const newDish = {
            name, desc, vibes: urls.filter(url => url.url.length > 0).map(url => ({
                url: url.url,
                source: url.source,
                title: url.title,
                tags: url.tags.map(tag => tag.id)
            }))
        };
        try {
            setIsLoading(true);
            const response = await createDish(newDish);
            if (response.status === 200) {
                location.reload();
            } else {
                console.error('Failed to create dish:', response);
            }
        } catch (error) {
            console.error('Failed to create dish:', error);
        }
    };

    return (
        <Page title='Add Dish'>
            <Container>
                <h1 className='text-2xl font-bold'>Add Dish</h1>
                <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                        label="Name"
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <TextField
                        label="Description"
                        variant="outlined"
                        value={desc}
                        onChange={(e) => setDesc(e.target.value)}
                        required
                    />
                    <h1 className='text-lg font-bold my-3'>Vibes</h1>
                    {urls.map((urlObj, index) => (
                        <div key={index} className="flex items-start gap-2">
                            <div className="flex-1 grid grid-cols-4 gap-2">
                                <TextField
                                    label="Title"
                                    variant="outlined"
                                    value={urlObj.title}
                                    onChange={(e) => handleUrlChange(index, 'title', e.target.value)}
                                    required
                                />
                                <Autocomplete
                                    options={Object.values(CREATOR_CHANNEL_TYPE)}
                                    value={urlObj.source}
                                    onChange={(e, newValue) => handleUrlChange(index, 'source', newValue)}
                                    renderInput={(params) => <TextField {...params} label="Source" variant="outlined" />}
                                />
                                <TextField
                                    label={`URL ${index + 1}`}
                                    variant="outlined"
                                    value={urlObj.url}
                                    onChange={(e) => handleUrlChange(index, 'url', e.target.value)}
                                    required
                                />
                                <Autocomplete
                                    multiple
                                    options={vibeTags}
                                    value={urlObj.tags}
                                    onChange={(e, newValue) => handleUrlChange(index, 'tags', newValue)}
                                    renderInput={(params) => <TextField {...params} label="Tags" variant="outlined" />}
                                />
                            </div>
                            <Button onClick={() => removeUrlField(index)} color="secondary" disabled={isLoading}>
                                Remove
                            </Button>
                        </div>
                    ))}
                    <Button variant="contained" onClick={addUrlField} disabled={isLoading}>
                        Add Another URL
                    </Button>
                    <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
                        Add Dish
                    </Button>
                </Box>
            </Container>
        </Page>
    );
}