import axios from "axios";
import { API_URL } from "../../../utils/constants";

export const updateBlock = async (id, data) => {
    try {
        const response = await axios.put(`${API_URL}/blocks/${id}`, data);
        return response;
    } catch (error) {
        return error.response.data.error
    }
}
