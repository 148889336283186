import axios from 'axios';
import { API_URL } from 'src/utils/constants';

export const getCuratedExperiences = async (query = {}) => {
    try {
        const response = await axios.get(`${API_URL}/curatedExperience`, {
            params: query
        });
        return response.data || [];
    } catch (error) {
        console.error('Error fetching dishes:', error);
        return [];
    }
}