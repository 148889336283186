/* eslint-disable */
import { useEffect, useState, useMemo } from "react"
import { Autocomplete, Box, Container, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, IconButton, TablePagination, Button, FormControlLabel, Switch, Accordion, AccordionSummary, AccordionDetails, Link } from "@mui/material"
import { getBlocks } from "./services/getBlocks"
import { getPlaces } from "../Places/services/getPlaces"
import { getdestinationBlockTypes } from "./services/getdestinationBlockTypes"
import Page from "src/components/Page"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import useLocalStorageKey from "src/utils/hooks/useLocalStorageKey"
import { addValueToLocalstorage } from "src/utils/localstorage/addValueToLocalstorage"
import { getValueToLocalstorage } from "src/utils/localstorage/getValueToLocalstorage"
import { removeValueFromLocalstorage } from "src/utils/localstorage/removeValueFromLocalstorage"
export default function ListBlocks() {
    const localStorageKey = useLocalStorageKey()
    const [destinationPlaceId, setDestinationPlaceId] = useState(null)
    const [blocks, setBlocks] = useState([])
    const [places, setPlaces] = useState([])
    const [blockTypes, setBlockTypes] = useState([])
    const [selectedBlockType, setSelectedBlockType] = useState(null)
    const [searchTerm, setSearchTerm] = useState('')
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('title')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [densePadding, setDensePadding] = useState(false)


    useEffect(() => {
        if (destinationPlaceId) {
            const query = { destinationPlaceId: destinationPlaceId }
            if (selectedBlockType) {
                query.blockType = selectedBlockType.id
            }
            getBlocks(query).then(setBlocks)
        }
    }, [destinationPlaceId, selectedBlockType])

    useEffect(() => {
        getPlaces({ isActive: true }).then(setPlaces).then(() => {
            const _destinationPlaceId = getValueToLocalstorage(localStorageKey, 'destinationPlaceId')
            if (_destinationPlaceId) {
                setDestinationPlaceId(_destinationPlaceId)
            }
        })
        getdestinationBlockTypes().then(setBlockTypes).then(() => {
            const _selectedBlockType = getValueToLocalstorage(localStorageKey, 'selectedBlockType')
            if (_selectedBlockType) {
                setSelectedBlockType(_selectedBlockType)
            }
        })
    }, [])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleSearch = (event) => {
        setSearchTerm(event.target.value.toLowerCase())
    }

    const handlePageChange = (event, newPage) => {
        setPage(newPage)
    }

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const filteredRows = useMemo(() => {
        if (searchTerm.length > 0) {
            return blocks.filter((row) => {
                return Object.keys(row).some(
                    (key) => row[key]?.toString().toLowerCase().includes(searchTerm)
                )
            })
        }
        return blocks
    }, [searchTerm, blocks])

    const sortedRows = useMemo(() => {
        return filteredRows.sort((a, b) => {
            let aValue = a[orderBy]
            let bValue = b[orderBy]

            aValue = aValue?.toString().toLowerCase()
            bValue = bValue?.toString().toLowerCase()

            if (aValue < bValue) return order === 'asc' ? -1 : 1
            if (aValue > bValue) return order === 'asc' ? 1 : -1
            return 0
        })
    }, [filteredRows, orderBy, order])

    const paginatedRows = useMemo(() => {
        return sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }, [sortedRows, page, rowsPerPage])
    return (
        <Page title="Blocks List">
            <Container>
                <div className="grid grid-cols-1 gap-4">
                    <div className="flex flex-col justify-between mb-2">
                        <div className="flex items-center justify-between w-full">
                            <h1 className="font-bold text-xl">Blocks</h1>
                        </div>
                        <div className="flex flex-col gap-1">
                            <h1 className="text-lf font-bold">Filter</h1>
                            <div className="flex items-center gap-10 w-full mt-3">
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={places}
                                    sx={{ width: 340 }}
                                    renderInput={(params) => <TextField {...params} label="Place" />}
                                    onChange={(_, newValue) => {
                                        if (newValue) {
                                            setDestinationPlaceId(newValue.id)
                                            addValueToLocalstorage(localStorageKey, { destinationPlaceId: newValue.id })
                                        } else {
                                            setDestinationPlaceId(null)
                                            removeValueFromLocalstorage(localStorageKey, 'destinationPlaceId')
                                        }
                                    }}
                                    value={places?.find(block => block.id === destinationPlaceId)?.label || ''}
                                />
                                <Autocomplete
                                    options={blockTypes}
                                    getOptionLabel={(option) => option.label || ''}
                                    renderInput={(params) => <TextField {...params} label="Block Type" />}
                                    sx={{ width: 340 }}
                                    value={selectedBlockType}
                                    onChange={(_, newValue) => {
                                        if (newValue) {
                                            setSelectedBlockType(newValue)
                                            addValueToLocalstorage(localStorageKey, { selectedBlockType: newValue })
                                        } else {
                                            setSelectedBlockType(null)
                                            removeValueFromLocalstorage(localStorageKey, 'selectedBlockType')
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <Box className="flex flex-col gap-2 ">
                        <h1 className="text-lg font-bold">Search</h1>
                        <TextField
                            sx={{ width: 340 }}
                            label="Search"
                            variant="outlined"
                            size="small"
                            onChange={handleSearch}
                        />
                    </Box>
                    <TableContainer>
                        <Table size={densePadding ? 'small' : 'medium'}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TableSortLabel
                                            active={orderBy === 'id'}
                                            direction={orderBy === 'id' ? order : 'asc'}
                                        >
                                            ID
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={orderBy === 'title'}
                                            direction={orderBy === 'title' ? order : 'asc'}
                                            onClick={(event) => handleRequestSort(event, 'title')}
                                        >
                                            Title
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        Actions
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedRows.map((block) => (
                                    <TableRow key={block.id}>
                                        <TableCell>{block.id}</TableCell>
                                        <TableCell>
                                            <Accordion>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                    <Typography>{block.title}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        {block.pricingOptions.map((option) => (
                                                            <div key={option.variantId} className="flex gap-3">
                                                                <strong>{option.variantId.title}:</strong> <p>₹{option.pricing[0].price}</p>
                                                            </div>
                                                        ))}
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        </TableCell>
                                        <TableCell>
                                            <Link href={`/dashboard/blocks/edit/${block.id}`} target="_blank">
                                                <Button variant="outlined" color="primary">
                                                    Edit
                                                </Button>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 50, 100]}
                        component="div"
                        count={sortedRows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                    />
                </div>
            </Container>
        </Page>
    )
}