export const PLACE_TYPE = {
    COUNTRY: 'country',
    STATE: 'state',
    TERRITORY: 'territory',
    REGION: 'region',
    CITY: 'city',
    ATTRACTION: 'attraction',
    FOOD: 'food',
    SHOPPING: 'shopping',
    TRAVEL_AGENT: 'travel-agent',
};

