import axios from 'axios';
import { API_URL } from '../../../utils/constants';
export const getAdditionalBlocks = async ({ placeId, blockType }) => {
    try {

        const response = await axios.get(`${API_URL}/blocks/additionalBlocks/${placeId}?blockType=${blockType}`);
        return response.data.blocks || []
    } catch (error) {
        console.error('Error fetching additional blocks:', error);
        return [];
    }
};