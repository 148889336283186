/* eslint-disable */
import React, { useState, useCallback } from 'react';
import { Container, Typography, Autocomplete, TextField, Button, TextareaAutosize } from '@mui/material';
import { API_URL } from 'src/utils/constants';
import axios from 'axios';

const AddUserContributionToPlace = () => {
    const [destinations, setDestinations] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedDestination, setSelectedDestination] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [about, setAbout] = useState('');

    const fetchPlaces = useCallback(async (query, cb) => {
        try {
            const response = await axios.get(`${API_URL}/place/mb/search?query=${query}`);
            cb(response.data.map(item => ({
                id: item.id,
                label: item.label
            })));
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    }, []);

    const fetchUsers = useCallback(async (query, cb) => {
        try {
            const response = await axios.get(`${API_URL}/admin/mb/users?name=${query}`);
            cb(response.data.map(item => ({
                id: item.id,
                label: `${item.name} (${item.phone}) ${item?.username || ''}`
            })));
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    }, []);

    const handleSubmit = async () => {
        try {
            const response = await axios.patch(`${API_URL}/place/mb/${selectedDestination.id}/creatorContributions`, {
                id: selectedUser.id,
                about: about
            });
            if (response.status === 200) {
                window.location.reload();
                console.log('User contribution updated successfully');
            } else {
                console.log('Failed to update user contribution');
            }
        } catch (error) {
            console.error('Failed to update data:', error);
        }
    }

    return (
        <div className="grid grid-cols-1 gap-4 min-w-[550px] p-2">
            <div className="mb-3 grid grid-cols-2 gap-4">
                <Autocomplete
                    className="w-full max-w-[400px]"
                    options={destinations}
                    getOptionLabel={(option) => option.label || ''}
                    renderInput={(params) => <TextField {...params} label="Destination" />}
                    value={selectedDestination}
                    onInputChange={(_, newInputValue) => fetchPlaces(newInputValue, setDestinations)}
                    onChange={(_, newValue) => setSelectedDestination(newValue)}
                />
                <Autocomplete
                    className="w-full max-w-[400px]"
                    options={users}
                    getOptionLabel={(option) => option.label || ''}
                    renderInput={(params) => <TextField {...params} label="User" />}
                    value={selectedUser}
                    onInputChange={(_, newInputValue) => fetchUsers(newInputValue, setUsers)}
                    onChange={(_, newValue) => setSelectedUser(newValue)}
                />
            </div>
            <div className="mb-3">
                <TextareaAutosize
                    minRows={4}
                    placeholder="About"
                    style={{ width: '100%' }}
                    value={about}
                    onChange={(e) => setAbout(e.target.value)}
                    className='outline outline-1'
                />
            </div>
            <Button variant="contained" onClick={handleSubmit}>Submit</Button>
        </div>
    );
};

export default AddUserContributionToPlace;
