/* eslint-disable */
import { useEffect, useState } from "react"
import { Autocomplete, Container, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material"
import { getBlocks } from "./services/getBlocks"
import { getPlaces } from "../Places/services/getPlaces"
import { getdestinationBlockTypes } from "./services/getdestinationBlockTypes"
import Page from "src/components/Page"
import { getBlock } from "./services/getBlock"
import moment from "moment"
import axios from "axios"
import { API_URL } from "src/utils/constants"
import DeleteIcon from '@mui/icons-material/Delete';
import useLocalStorageKey from "src/utils/hooks/useLocalStorageKey"
import { addValueToLocalstorage } from "src/utils/localstorage/addValueToLocalstorage"
import { getValueToLocalstorage } from "src/utils/localstorage/getValueToLocalstorage"
import { removeValueFromLocalstorage } from "src/utils/localstorage/removeValueFromLocalstorage"


export default function CreateItineraryBlock() {
    const localStorageKey = useLocalStorageKey()
    const [destinationPlaceId, setDestinationPlaceId] = useState(null)
    const [blocks, setBlocks] = useState([])
    const [places, setPlaces] = useState([])
    const [blockTypes, setBlockTypes] = useState([])
    const [customerDetails, setCustomerDetails] = useState({ name: "", phone: "", date: '' })
    const [selectedBlockType, setSelectedBlockType] = useState(null)
    const [selectedItineraryIds, setSelectedItineraryIds] = useState([])
    const [selectedDefaultVariant, setSelectedDefaultVariant] = useState(null)
    const [selectedItineraries, setSelectedItineraries] = useState([])
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({ name: "", phone: "", date: "", defaultVariant: "" })

    useEffect(() => {
        if (destinationPlaceId) {
            const query = { destinationPlaceId: destinationPlaceId }
            if (selectedBlockType) {
                query.blockType = selectedBlockType
            }
            getBlocks(query).then((blk => {
                const labelBlocks = blk.map(item => ({
                    id: item.id,
                    label: item.title,
                }))
                setBlocks(labelBlocks)
            }))
        }
    }, [destinationPlaceId, selectedBlockType])

    useEffect(() => {
        getPlaces({ isActive: true }).then(setPlaces).then(() => {
            const _destinationPlaceId = getValueToLocalstorage(localStorageKey, 'destinationPlaceId')
            if (_destinationPlaceId) {
                setDestinationPlaceId(_destinationPlaceId)
            }
        })
        getdestinationBlockTypes().then(setBlockTypes).then(() => {
            const _selectedBlockType = getValueToLocalstorage(localStorageKey, 'selectedBlockType')
            if (_selectedBlockType) {
                setSelectedBlockType(_selectedBlockType)
            }
        })
    }, [])

    useEffect(() => {
        if (selectedItineraryIds.length > 0) {
            const fetchItineraries = async () => {
                const itineraries = await Promise.all(selectedItineraryIds.map(id => getBlock(id.id)))
                itineraries.sort((a, b) => selectedItineraryIds.indexOf(a.id.id) - selectedItineraryIds.indexOf(b.id.id))
                const blockPricing = []
                const finalPricing = itineraries.reduce((acc, itinerary) => {
                    itinerary.pricingOptions.forEach(item => {
                        const existingVariant = acc.find(variant => variant.variantId === item.variantId._id)
                        if (existingVariant) {
                            item.pricing.forEach(price => {
                                const existingPrice = existingVariant.pricing.find(p => p.pricingId === price.pricingId)
                                if (existingPrice) {
                                    existingPrice.price += price.price
                                    existingPrice.basePrice += price.basePrice
                                } else {
                                    existingVariant.pricing.push({
                                        pricingId: price.pricingId,
                                        price: price.price,
                                        basePrice: price.basePrice
                                    })
                                }
                            })
                            itineraries.forEach(block => {
                                if (!existingVariant.additionalBlockId.includes(block.id)) {
                                    existingVariant.additionalBlockId.push(block.id)
                                }
                            })
                        } else {
                            acc.push({
                                variantId: item.variantId._id,
                                pricing: item.pricing.map(price => ({
                                    pricingId: price.pricingId,
                                    price: price.price,
                                    basePrice: price.basePrice
                                })),
                                additionalBlockId: itineraries.map(block => block.id)
                            })
                        }
                    })
                    return acc
                }, [])

                const defaultPricing = itineraries[0].pricingOptions.map(item => ({
                    label: item.variantId.title,
                    id: item.variantId._id
                }))
                const totalPricing = []

                itineraries.forEach(itinerary => {
                    blockPricing.push({
                        id: itinerary.id,
                        title: itinerary.title,
                        pricing: itinerary.pricingOptions.map(item => {
                            const price = item.pricing[0].price
                            const finalPriceIndex = totalPricing.findIndex(it => it.title === item.variantId.title)
                            if (finalPriceIndex > -1) {
                                totalPricing[finalPriceIndex].price += price
                            } else {
                                totalPricing.push({
                                    price,
                                    title: item.variantId.title
                                })
                            }
                            return ({
                                price: price,
                                title: item.variantId.title
                            })
                        })
                    })
                })

                const finalItineary = itineraries.flatMap(data => {
                    return data.itinerary
                })
                setSelectedItineraries({
                    finalItineary,
                    blockPricing,
                    totalPricing,
                    finalPricing,
                    defaultPricing
                })
            }
            fetchItineraries()
        } else {
            setSelectedItineraries([])
        }
    }, [selectedItineraryIds])

    const validateInputs = () => {
        let valid = true
        let newErrors = { name: "", phone: "", date: "", defaultVariant: "" }

        if (!customerDetails.name) {
            newErrors.name = "Name is required"
            valid = false
        }

        const phoneRegex = /^[0-9]{10}$/
        if (!phoneRegex.test(customerDetails.phone)) {
            newErrors.phone = "Phone must be a 10-digit number"
            valid = false
        }

        if (!customerDetails.date) {
            newErrors.date = "Date is required"
            valid = false
        } else if (moment(customerDetails.date).isBefore(moment(), 'day')) {
            newErrors.date = "Date cannot be in the past"
            valid = false
        }
        if (!selectedDefaultVariant) {
            newErrors.defaultVariant = "Default variant is required"
            valid = false
        }

        setErrors(newErrors)
        return valid
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target
        let val = value
        if (name === 'phone') {
            val = val.replace(/[^0-9]/g, '').slice(0, 10)
        }
        setCustomerDetails(prevDetails => ({ ...prevDetails, [name]: val }))
    }

    const handleSubmit = async () => {
        if (validateInputs()) {
            const body = {
                ...customerDetails,
                startDate: moment(customerDetails.date).format('YYYY-MM-DD'),
                endDate: moment(customerDetails.date).add(Math.max((selectedItineraries?.finalItineary?.length || 0) - 1, 0), 'day').format('YYYY-MM-DD'),
                itinerary: selectedItineraries.finalItineary,
                pricingOptions: selectedItineraries.finalPricing,
                defaultVariantId: selectedDefaultVariant
            }
            setLoading(true)
            const res = await axios.post(`${API_URL}/curatedExperience`, body)
            if (res.status === 200) {
                window.open(`https://www.passprt.co/curated/${res.data.block._id}`, '_blank')
                alert('Itinerary created successfully')
            } else {
                alert('Error creating Itinerary')
            }
            setLoading(false)
            console.log("Form is valid, submitting data:", customerDetails)
        }
    }

    const handleRemoveItinerary = (id) => {
        setSelectedItineraryIds(prev => prev.filter(item => item.id !== id))
    }

    const renderTable = () => {
        if (!selectedItineraries || selectedItineraries?.length === 0) return null

        const { blockPricing, totalPricing } = selectedItineraries
        const blockNames = blockPricing[0]?.pricing?.map(item => item.title || '')
        return (
            <>
                <h1 className="text-xl font-bold">Pricing</h1>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Title</TableCell>
                                {blockNames.map((item, index) => (
                                    <TableCell key={index}>{item}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {blockPricing.map((price, index) => (
                                <TableRow key={index}>
                                    <TableCell>{price.title}</TableCell>
                                    {price.pricing.map((item, index) => (
                                        <TableCell key={index}>{item.price}</TableCell>
                                    ))}
                                    <TableCell>
                                        <IconButton onClick={() => handleRemoveItinerary(price.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell className="font-bold">Final</TableCell>
                                {totalPricing.map((item, index) => (
                                    <TableCell className="font-bold" key={index}>{item.price}</TableCell>
                                ))}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }
    return (
        <Page title="Create itinerary Blocks">
            <Container>
                <div className="grid grid-cols-1 gap-4">
                    <div className="flex flex-col justify-between mb:2 gap-4">
                        <div className="flex items-center justify-between w-full">
                            <h1 className="font-bold text-xl">Blocks</h1>
                        </div>
                        <div className="grid grid-cols-2 gap-2">
                            <div className="col-span-full grid grid-cols-3 w-full gap-2">
                                <TextField
                                    label="Customer Name"
                                    name="name"
                                    value={customerDetails.name}
                                    onChange={handleInputChange}
                                    error={!!errors.name}
                                    helperText={errors.name}
                                />
                                <TextField
                                    label="Customer Phone"
                                    name="phone"
                                    value={customerDetails.phone}
                                    onChange={handleInputChange}
                                    error={!!errors.phone}
                                    helperText={errors.phone}
                                />
                                <TextField
                                    label="Date"
                                    name="date"
                                    type="date"
                                    value={customerDetails.date}
                                    onChange={handleInputChange}
                                    error={!!errors.date}
                                    helperText={errors.date}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <Autocomplete
                                id="combo-box-demo"
                                options={places}
                                renderInput={(params) => <TextField {...params} label="Place" />}
                                onChange={(_, newValue) => {
                                    if (newValue) {
                                        setDestinationPlaceId(newValue.id)
                                        addValueToLocalstorage(localStorageKey, { destinationPlaceId: newValue.id })

                                    } else {
                                        setDestinationPlaceId(null)
                                        removeValueFromLocalstorage(localStorageKey, 'destinationPlaceId')

                                    }
                                }}
                                value={places.find(place => place.id === destinationPlaceId) || null}
                            />
                            <Autocomplete
                                options={blockTypes}
                                getOptionLabel={(option) => option.label || ''}
                                renderInput={(params) => <TextField {...params} label="Blocks" />}
                                onChange={(_, newValue) => {
                                    if (newValue) {
                                        setSelectedBlockType(newValue.id)
                                        addValueToLocalstorage(localStorageKey, { selectedBlockType: newValue.id })
                                    } else {
                                        setSelectedBlockType(null)
                                        removeValueFromLocalstorage(localStorageKey, 'selectedBlockType')
                                    }
                                }}
                                value={blockTypes.find(blockType => blockType.id === selectedBlockType) || null}
                            />
                        </div>
                        <Autocomplete
                            options={blocks}
                            getOptionLabel={(option) => option.label || ''}
                            renderInput={(params) => <TextField {...params} label="Select Blocks" />}
                            onChange={(_, newValue) => {
                                if (newValue) {
                                    setSelectedItineraryIds(prev => [...prev, newValue])
                                }
                            }}
                            value={blocks.filter(block => selectedItineraryIds.includes(block.id))}
                        />
                        <div className="flex flex-col gap-2 my-5">
                            {selectedItineraries && selectedItineraries?.finalItineary?.length > 0 && <>
                                <h1 className="text-xl font-bold">Itinerary</h1>
                                {selectedItineraries.finalItineary.map((item, index) => (
                                    <div className="flex flex-col gap-2" key={index}>
                                        <div className="flex items-center gap-1 font-bold text-lg ">
                                            <p>Day {index + 1}</p>
                                            <psan>-</psan>
                                            <p>{moment(customerDetails.date).add(index, 'day').format('D MMMM')}</p>
                                        </div>
                                        <div className="flex w-full">
                                            {item.title}
                                        </div>
                                    </div>
                                ))}
                            </>}
                        </div>
                        {renderTable()}
                        {selectedItineraries && selectedItineraries?.defaultPricing?.length > 0 && <Autocomplete
                            id="combo-box-demo"
                            options={selectedItineraries.defaultPricing}
                            renderInput={(params) => <TextField {...params} label="Default variant" />}
                            onChange={(_, newValue) => {
                                if (newValue) {
                                    setSelectedDefaultVariant(newValue.id)
                                } else {
                                    setSelectedDefaultVariant(null)
                                }
                            }}
                            value={selectedItineraries.defaultPricing.find(place => place.id === selectedDefaultVariant) || null}
                        />}
                        {errors.defaultVariant?.length > 0 && errors.defaultVariant.trim() !== '0' && <p className="text-red-500">{errors.defaultVariant}</p>}
                        <Button disabled={loading} variant="contained" color="primary" onClick={handleSubmit} className="mt-4">
                            Create Itinerary
                        </Button>
                    </div>
                </div>
            </Container>
        </Page>
    )
}