import axios from 'axios';
import { API_URL } from 'src/utils/constants';

export const getBlock = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/blocks/${id}`);
        return response.data.blocks || {};
    } catch (error) {
        console.error('Error fetching dishes:', error);
        return {};
    }
}