/* eslint-disable */
import { useEffect, useState } from 'react';
import axios from 'axios';
import './../components/gallery/gallery.css';
import CircularProgress from '@mui/material/CircularProgress';
import { Autocomplete, Button, Container, Stack, TextField, Typography } from '@mui/material';

import Page from '../components/Page';
import CreateGenericActivityParams from '../components/activityList/createGenericActivityParams';
import { API_URL } from '../utils/constants';
import { ActivityVariant } from '../components/activityList/activityVariant';
import { ActivityBanners } from '../components/banners/activityBanners';
import { ACTIVITY_BOOKING_TYPE } from 'src/utils/constants/activity';

export default function ActivityPage() {
  const [newVariants, setNewVariants] = useState([]);
  const [banners, setBanners] = useState([]);
  const [bannerSections, setBannerSections] = useState({});
  const [activityCommonParams, setActivityCommonParams] = useState({});
  const [isLoading, setLoader] = useState(false);
  const [bookingType, setBookingType] = useState('');
  const [externalBookingUrl, setExternalBookingUrl] = useState('');

  const handleActivityBannerChange = (newBanners, bannerSections) => {
    setBanners(newBanners);
    setBannerSections(bannerSections);
  };

  useEffect(() => {
    console.log(banners);
    console.log(bannerSections);
  }, [banners, bannerSections]);

  const onVariantChange = (variants) => {
    setNewVariants(variants);
  };

  const openInNewTab = (href) => {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: href,
    }).click();
  };

  const saveFinalData = async () => {
    setLoader(true);
    const body = {
      ...activityCommonParams,
      banners: banners.filter((b) => b != '' && b != ' '),
      bannerSections: bannerSections,
      newVariants: newVariants,
      channel: 'motherboard',
      bookingType: bookingType,
      externalBookingUrl: bookingType === ACTIVITY_BOOKING_TYPE.PARTNER_MANAGED ? externalBookingUrl : undefined,
    };
    await axios
      .post(`${API_URL}/activity`, body)
      .then((response) => {
        setLoader(false);
        openInNewTab(`https://www.passprt.co/activity/${response.data.slug}`);
      })
      .catch((error) => console.log(error)).finally(() => setLoader(false));
  };

  return (
    <Page title="Add Activity">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Create Activity
        </Typography>
        <CreateGenericActivityParams
          passActivityData={(value) => setActivityCommonParams(value)}
        />
        <div className="flex flex-col gap-2 my-4 bg-white p-4 rounded-lg border border-gray-200">
          <h1 className="text-lg font-bold">Booking Type</h1>
          <Autocomplete
            options={Object.entries(ACTIVITY_BOOKING_TYPE).map(([key, value]) => ({ id: value, label: key }))}
            sx={{ width: 340 }}
            renderInput={(params) => <TextField {...params} label="Booking Type" />}
            onChange={(_, newValue) => {
              if (newValue) {
                setBookingType(newValue.id);
              } else {
                setBookingType('');
              }
            }}
            value={Object.entries(ACTIVITY_BOOKING_TYPE).find(([key, value]) => value === bookingType)?.label || bookingType}
          />
          {bookingType === ACTIVITY_BOOKING_TYPE.PARTNER_MANAGED && (
            <div>
              <h1>External Booking URL</h1>
              <TextField
                label="External Booking URL"
                value={externalBookingUrl}
                onChange={(e) => setExternalBookingUrl(e.target.value)}
                fullWidth
              />
            </div>
          )}
        </div>
        <ActivityBanners
          handleActivityBannerChange={handleActivityBannerChange}
        />
        <ActivityVariant
          activityType={activityCommonParams.type}
          onVariantChange={onVariantChange}
        />

        <Stack direction="column" sx={{ mt: 5 }}></Stack>

        <div className="finalsave">
          <Button
            className="save"
            disabled={isLoading}
            enabled
            onClick={saveFinalData}
          >
            SAVE
          </Button>
          {isLoading ? <CircularProgress /> : null}
        </div>
      </Container>
    </Page>
  );
}
