/* eslint-disable */
import _ from 'lodash';
import React, { useMemo, useCallback } from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Typography, IconButton, Autocomplete, TextField, Chip, Button } from '@mui/material';
import { API_URL } from 'src/utils/constants';
import { Link, useNavigate } from 'react-router-dom';
import Page from 'src/components/Page';

const activityTypes = [
    'stay',
    'activity',
    'taxi-tour'
];
const activityStatuses = [
    'live',
    'draft',
];

export default function ActivityRanking() {
    const [activities, setActivities] = useState([]);
    const [selectedActivityStatus, setSelectedActivityStatus] = useState(null);
    const [selectedActivityType, setSelectedActivityType] = useState(null);
    const [destinations, setDestinations] = useState([]);
    const [filteredActivities, setFilteredActivities] = useState([]);
    const [selectedDestination, setSelectedDestination] = useState(null);
    const [loader, setLoader] = useState(false);

    const getActivities = useCallback(async (query) => {
        try {
            const res = await axios.get(`${API_URL}/activity/mb${query}`);
            setActivities(res.data.map(item => ({
                ...item,
                displayScore: item.displayScore || 0,
            })));
        } catch (error) {
            console.log(error);
        }
    }, []);

    useMemo(() => {
        const filtered = activities

        const filteredByType = selectedActivityType ?
            filtered.filter((activity) => activity.type === selectedActivityType)
            : filtered;

        const filteredByStatus = selectedActivityStatus ?
            filteredByType.filter((activity) => activity.status === selectedActivityStatus)
            : filteredByType;
        setFilteredActivities(filteredByStatus.sort((a, b) => b.displayScore - a.displayScore));
    }, [activities, destinations, selectedDestination, selectedActivityStatus, selectedActivityType]);

    useEffect(() => {
        if (!destinations.length) {
            axios
                .get(`${API_URL}/place/mb?isActive=true`)
                .then((res) => {
                    setDestinations(res.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, []);

    const handleRankChange = useCallback((e, id) => {
        const newRank = e.target.value.replace(/[^0-9]/g, '');
        setActivities(prevActivities =>
            prevActivities.map(activity =>
                activity.id === id ? { ...activity, displayScore: parseInt(newRank || 0) } : activity
            )
        );
    }, []);

    const handleSave = async () => {
        setLoader(true);
        const data = activities.map(activity => ({ id: activity.id, displayScore: activity.displayScore }));
        await axios.post(`${API_URL}/admin/mb/activity/displayScore`, {
            activities: data
        })
            .then(res => {
                console.log(res.status)
                if (res.status === 200) {
                    location.reload()
                }
            })
            .catch(error => {
                console.log(error)
            })

    }

    useEffect(() => {
        if (selectedDestination) {
            const query = `?placeId=${selectedDestination}`;
            getActivities(query);
        }
    }, [selectedDestination]);

    const onDestinationChange = (_, newValue) => {
        if (newValue?.id) {
            setSelectedDestination(newValue.id || null);
        } else {
            setSelectedDestination(newValue);
        }
    };

    return (
        <Page title="Activity Ranking">

            <Container>
                <div className='flex items-center justify-between'>

                    <Typography
                        className='flex items-center justify-between'
                        variant="h4" sx={{ mb: 5 }}>
                        <p>Activity Ranking</p>
                    </Typography>
                </div>
                <div className='mb-3 grid grid-cols-3'>
                    <div>
                        <label>Destination</label>
                        <Autocomplete
                            id="combo-box-demo"
                            options={destinations}
                            sx={{ width: 340 }}
                            renderInput={(params) => <TextField {...params} label="" />}
                            value={destinations.find(destination => destination.id === selectedDestination)}
                            onChange={onDestinationChange}
                        />
                    </div>
                    <div>
                        <label>Type</label>
                        <Autocomplete
                            id="combo-box-demo"
                            options={activityTypes}
                            sx={{ width: 340 }}
                            renderInput={(params) => <TextField {...params} label="" />}
                            value={selectedActivityType}
                            onChange={(_, newValue) => { setSelectedActivityType(newValue) }}
                        />
                    </div>
                    <div>
                        <label>Status</label>
                        <Autocomplete
                            id="combo-box-demo"
                            options={activityStatuses}
                            sx={{ width: 340 }}
                            renderInput={(params) => <TextField {...params} label="" />}
                            value={selectedActivityStatus}
                            onChange={(_, newValue) => { setSelectedActivityStatus(newValue) }}
                        />
                    </div>
                </div>
                <div className='grid grid-cols-1'>
                    <div className='flex items-center justify-between'>
                        <div>
                            <label>Activity</label>
                        </div>
                        <div>
                            <label>Rank</label>
                        </div>
                    </div>
                    {filteredActivities.map((activity) => {
                        return (
                            <div key={activity.id} className='flex items-center justify-between'>
                                <p>{activity.label}</p>
                                <input
                                    value={activity.displayScore}
                                    onChange={(e) => handleRankChange(e, activity.id)}
                                />
                            </div>
                        )
                    })}
                </div>
                <div>
                    <Button disabled={loader} variant='contained' color='primary' onClick={handleSave}>Save</Button>
                    <p>{loader ? 'Saving...' : ''}</p>
                </div>

            </Container>
        </Page>
    );
}
