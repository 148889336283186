import axios from 'axios'
import { API_URL } from 'src/utils/constants'


export const getPlaces = async (query) => {
    try {
        const response = await axios.get(`${API_URL}/place/mb`, {
            params: query
        });
        return response.data || [];
    } catch (error) {
        console.error('Error fetching dishes:', error);
        return [];
    }
}