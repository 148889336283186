import React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  convertToRaw,
  EditorState,
  convertFromHTML,
  ContentState,
} from 'draft-js';
import { Button } from '@mui/material';
import { Editor } from 'react-draft-wysiwyg';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import clearFormatting from 'draft-js-clear-formatting';

const FlyHTMLeditor = (props) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorHTML, setEditorHTML] = useState('');
  const [showHTML, setShowHTML] = useState(false);

  const onEditorStateChange = (editorState) => {
    const htmlString = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    props.onEditorStateChange(htmlString, props.attribute);
    setEditorHTML(htmlString);
    setEditorState(editorState);
  };

  useEffect(() => {
    if (props.initialData) {
      const state = EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(props.initialData))
      );
      setEditorHTML(props.initialData);
      setEditorState(state);
    }
  }, [props.initialData]);

  const toggleHTMLView = () => {
    setShowHTML(!showHTML);
  };

  const clearFormat = () => {
    const options = {
      inline: true,
      entities: true,
      lists: true,
    };
    const newEditorState = clearFormatting(editorState, options);
    const htmlString = draftToHtml(
      convertToRaw(newEditorState.getCurrentContent())
    );
    setEditorHTML(htmlString);
    setEditorState(newEditorState);
  };

  return (
    <div>
      <br></br>
      <Button
        variant="contained"
        onClick={toggleHTMLView}
        style={{ float: 'right', margin: '0px 120px 0px 20px' }}
      >
        {showHTML ? 'show text' : 'show HTML'}
      </Button>

      <Button
        variant="contained"
        onClick={clearFormat}
        style={{ float: 'right', margin: '0px 120px 0px 20px' }}
      >
        clear formatting
      </Button>

      {!showHTML ? (
        <div>
          <Editor
            onEditorStateChange={onEditorStateChange}
            name="desc"
            editorState={editorState}
            handlePastedText={clearFormat}
            toolbar={{
              options: [
                'inline',
                'blockType',
                'list',
                'link',
                'image',
                'remove',
                'history',
              ],
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
              blockType: {
                inDropdown: true,
                options: ['Normal', 'H1', 'H2'],
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
              },
              fontFamily: undefined,
            }}
          />
          <br></br>
        </div>
      ) : (
        <div
          style={{ minHeight: '240px', border: '1px', borderStyle: 'solid' }}
        >
          <p>{`${editorHTML}`}</p>
        </div>
      )}
    </div>
  );
};

FlyHTMLeditor.propTypes = {
  onEditorStateChange: PropTypes.func,
  attribute: PropTypes.string,
  initialData: PropTypes.string,
};

export default FlyHTMLeditor;
