/* eslint-disable */
import React, { useState, useCallback } from 'react';
import { Container, Typography, Autocomplete, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { API_URL } from 'src/utils/constants';
import axios from 'axios';
import Page from 'src/components/Page';

import AddUserContributionToPlace from './AddUserContributionToPlace';

const CommunityListPage = () => {
    const [destinations, setDestinations] = useState([]);
    const [selectedDestination, setSelectedDestination] = useState(null);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [creatorContributions, setCreatorContributions] = useState([]);
    const fetchPlaces = useCallback(async (query, cb) => {
        try {
            const response = await axios.get(`${API_URL}/place/mb/search?query=${query}`);
            cb(response.data.map(item => ({
                id: item.id,
                label: item.label
            })));
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    }, []);

    const getPlace = useCallback(async (id) => {
        const response = await axios.get(`${API_URL}/place/mb?id=${id}`);
        setCreatorContributions(response.data[0].creatorContributions);
    }, []);

    const handleAdd = () => {
        setOpenAddModal(true);
    }

    const handleCloseAddModal = () => {
        setOpenAddModal(false);
    }

    return (
        <Page title="Community">
            <Container>
                <Typography variant="h6" sx={{ mb: 2 }}>Community</Typography>
                <div className="mb-3 flex items-start justify-between gap-4">
                    <Autocomplete
                        className="w-full max-w-[400px]"
                        options={destinations}
                        getOptionLabel={(option) => option.label || ''}
                        renderInput={(params) => <TextField {...params} label="Destination" />}
                        value={selectedDestination}
                        onInputChange={(_, newInputValue) => fetchPlaces(newInputValue, setDestinations)}
                        onChange={(_, newValue) => {
                            if (newValue) {
                                setSelectedDestination(newValue)
                                getPlace(newValue.id)
                            }
                        }}
                    />
                    <Button variant="contained" onClick={handleAdd}>Add</Button>
                </div>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>About</TableCell>
                                <TableCell>Passprt</TableCell>
                                <TableCell>Instagram</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {creatorContributions.map((contribution) => (
                                <TableRow key={contribution.id}>
                                    <TableCell>{`${contribution.name} ${contribution?.lastName || ''}`}</TableCell>
                                    <TableCell>{contribution.about}</TableCell>
                                    <TableCell>{contribution.username}</TableCell>
                                    <TableCell>
                                        <a href={`https://instagram.com/${contribution.instagramUsername}`} target="_blank" rel="noopener noreferrer">
                                            @{contribution.instagramUsername}
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button variant="contained" sx={{ mt: 2 }}>Save</Button>

                <Dialog open={openAddModal} onClose={handleCloseAddModal} >
                    <DialogTitle>Add User Contribution</DialogTitle>
                    <DialogContent>
                        <AddUserContributionToPlace />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseAddModal} color="primary">Cancel</Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </Page>
    );
};

export default CommunityListPage;