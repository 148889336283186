export const getValueToLocalstorage = (key, value) => {
    if (typeof window !== 'undefined') {
        const localStorageString = localStorage.getItem(key);
        if (localStorageString) {
            try {
                const localStorageObject = JSON.parse(localStorageString);
                return localStorageObject[value];
            } catch (error) {
                console.error('Error parsing localStorage:', error);
                return false;
            }
        }
    }
    return false;
}