export const API_URL = process.env.REACT_APP_ENV === 'PROD' ? 'https://api.passprt.co' : 'https://api-beta.passprt.co';
// export const API_URL = 'https://api.passprt.co'
// export const API_URL = 'http://localhost:3006';
export const CLOUDINARY_CLOUD_NAME = 'passprt-images';
export const CLOUDINARY_API_KEY = '151917624466915';
export const PASSPRT_PARTNER_ID = 'prl13x';
export const CREATOR_CHANNEL_TYPE = {
    INSTAGRAM: 'instagram',
    FACEBOOK: 'facebook',
    X: 'x',
    GOOGLE: 'google',
    PASSPRT: 'passprt',
    SNAPCHAT: 'snapchat',
};
